<template>
  <b-card-title>
    <div class="float-left">{{ month }}</div>
    <div class="float-right">
      <small><small><small class="text-muted text-right">
            {{ date }}
          </small></small></small>
    </div>
    <div class="clearfix"></div>
  </b-card-title>
</template>

<script>

export default {
  name: 'ContractHeader',
  props: ['month', 'date']
}
</script>
