<template>
  <div>
    <h5>Open Positions</h5>
    <b-table-simple small hover caption-top>
      <b-thead>
        <b-tr>
          <b-th>Contract</b-th>
          <b-th>Commodity</b-th>
          <b-th>Trade Type</b-th>
          <b-th class="text-right">Premium Exposure</b-th>
          <b-th class="text-right">Margin Exposure</b-th>
          <b-th class="text-right">Avg. Strike</b-th>
          <b-th class="text-right">Avg. Price</b-th>
          <b-th class="text-right">Net Lots</b-th>
          <b-th class="text-right">Net Delta</b-th>
          <b-th class="text-right">Mark-to-Market</b-th>
          <b-th class="text-right">Realized</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="item in collection"
          v-bind:key="itemKey(item)"
        >
          <b-td>{{ ppContract(item.tennor) }}</b-td>
          <b-td>{{ ppCommodity(item.commodity) }}</b-td>
          <b-td>{{ ppTradeType(item.type) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.premium_exposure) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.margin_exposure) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.strike) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.price) }}</b-td>
          <b-td class="text-right">{{ ppFloat(item.net_lots) }}</b-td>
          <b-td class="text-right">{{ ppFloat(item.net_d) }}</b-td>
          <b-td class="text-right">
            <span :class="{ 'text-danger': item.m2m < 0 }">
              {{ format(item.m2m) }}
            </span>
          </b-td>
          <b-td class="text-right">
            <span :class="{ 'text-danger': item.partially_realized < 0 }">
              {{ hideZeroFormat(item.partially_realized) }}
            </span>
          </b-td>
        </b-tr>
        <b-tr v-if="count == 0">
          <b-td colspan="10">
            <b-alert class="text-center" variant="default" show>No Data Available</b-alert>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot v-if="count > 0">
        <b-tr>
          <b-th>Total</b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th class="text-right">{{ hideZeroFormat(sums['premium_exposure']) }}</b-th>
          <b-th class="text-right">{{ hideZeroFormat(sums['margin_exposure']) }}</b-th>
          <b-th class="text-right">{{ hideZeroFormat(averages['strike']) }}</b-th>
          <b-th class="text-right">{{ hideZeroFormat(averages['price']) }}</b-th>
          <b-th class="text-right">{{ ppFloat(sums['net_lots']) }}</b-th>
          <b-th class="text-right">{{ ppFloat(sums['net_d']) }}</b-th>
          <b-th class="text-right">
            <span :class="{ 'text-danger': sums['m2m'] < 0 }">
              {{ format(sums['m2m']) }}
            </span>
          </b-th>
          <b-th class="text-right">
            <span :class="{ 'text-danger': sums['partially_realized'] < 0 }">
              {{ format(sums['partially_realized']) }}
            </span>
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {ppMixin, tableMixin} from '@/mixins'

export default {
  props: ['data'],
  mixins: [ppMixin, tableMixin]
}
</script>
