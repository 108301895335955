<template>
  <div>
  </div>
</template>

<script>
import * as d3 from "d3"
import { colorsMixin } from '@/mixins'

export default {
  props: {
    data: {
      default: () => ({
        min_value: 5000,
        max_value: 25000,
        value: 20000,
        forecast: 22000,
        ll_50: 21000,
        ul_50: 23000,
        ll_70: 20000,
        ul_70: 24000
      }),
      type: Object
    },
    route: {
      default: 'Route',
      type: String
    },
    week: {
      default: 1,
      type: Number
    },
    canvasSize: {
      default: 300,
      type: Number
    },
    mode: {
      default: 'forecast',
      type: String
    },
    range: {
      default: 'dynamic',
      type: String
    },
    forecastRange: {
      default: 'standard',
      type: String
    },
    spotLabel: {
      default: 'Spot',
      type: String
    }
  },
  mixins: [ colorsMixin ],
  watch: {
    data(newData, oldData) {
      this.oldData = oldData
      this.$nextTick(this.draw)
    },
    mode(newMode, oldMode) {
      if (newMode != oldMode) {
        this.oldData = this.data
        this.$nextTick(this.draw)
      }
    }
  },
  methods: {
    async draw() {
      const size = this.canvasSize * 0.8
      const strokeWidth = size / 18.0
      const center = this.canvasSize / 2.0
      const width = size - strokeWidth
      const margin = size * 0.1
      const radius = (width - margin) / 2.0
      const innerGaugeRadius = radius / 1.4
      const circumference = radius * 2 * Math.PI
      const deg2rad = deg => (deg * Math.PI) / 180.0
      //const rad2deg = rad => (rad * 180.0) / Math.PI

      const minValue = this.range == 'dynamic' ? Math.min(
        this.data.min_value,
        this.data.ll_50,
        this.data.ll_70
      ) : 0
      const maxValue = Math.max(
        this.data.max_value,
        this.data.ul_50,
        this.data.ul_70
      )
      const gaugeScale = d3.scaleLinear()
        .domain([minValue, maxValue])
        .range([deg2rad(45), deg2rad(315)])

      const ticks = gaugeScale.ticks(7)
      const tickFormat = gaugeScale.tickFormat(7, "~s")

      const valueToGaugeCoord = (v, radius, offset=0) => {
        const x = center - radius * Math.sin(gaugeScale(v) + offset)
        const y = center + radius * Math.cos(gaugeScale(v) + offset)
        return {x, y}
      }

      let svg = d3.select(this.$el).select("svg")

      if (svg.empty()) {
        svg = d3.select(this.$el)
          .append("svg")
          .attr("width", this.canvasSize)
          .attr("height", this.canvasSize)
      }

      svg.selectAll("circle.outer-gauge")
        .data([{}])
        .join("circle")
          .attr("class", "outer-gauge")
          .attr("stroke", this.colors.LIGHT_GRAY)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth)
          .attr("cx", center)
          .attr("cy", center)
          .attr("r", radius)
          .attr(
            "stroke-dasharray",
            `${circumference*270/360} ${circumference*90/360}`
          )
          .attr("transform", `rotate(135 ${center} ${center})`)

      svg.selectAll("text.title")
        .data([`${this.route} W+${this.week}`])
        .join("text")
          .attr("class", "title")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-weight", 500)
          .attr("fill", this.colors.BLACK)
          .attr("font-size", size / 13.0)
          .attr("x", center)
          .attr("y", center + radius)
          .text(d => d)

      svg.selectAll("path.tick")
        .data(ticks)
        .join("path")
          .attr("class", "tick")
          .attr("stroke", "black")
          .attr("stroke-width", radius / 70.0)
          .attr("d", d => {
            const p1 = valueToGaugeCoord(d, radius*1.09)
            const p2 = valueToGaugeCoord(d, radius*1.13)
            return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
          })

      svg.selectAll("text.tick-label")
        .data(ticks.map(v => {
          const d = valueToGaugeCoord(v, radius*1.20)
          d['v'] = v
          return d
        }))
        .join("text")
        .attr("class", "tick-label")
        .attr("font-family", "arial")
        .attr("font-weight", 500)
        .attr("fill", this.colors.BLACK)
        .attr("font-size", size / 28.0)
        .attr("text-anchor", d => {
          const angle = gaugeScale(d.v)
          if (angle < Math.PI * 0.8) return "end"
          if (angle > Math.PI * 1.2) return "start"
          return "middle"
        })
        .text(d => tickFormat(d.v))
        .attr("x", d => d.x)
        .attr("y", d => d.y)

      svg.selectAll("path.ci70")
        .data([this.data])
        .join("path")
          .attr("class", "ci70")
          .attr("stroke", this.colors.LIGHT_BLUE)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth)
          .transition()
          .attr("d", d => {
            const ul70 = valueToGaugeCoord(d.ul_70, radius)
            const ll70 = valueToGaugeCoord(d.ll_70, radius)
            const move = `M${ll70.x} ${ll70.y}`
            const arc = `A${radius} ${radius} 0 0 1 ${ul70.x} ${ul70.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const ll70Interp = d3.interpolate(this.oldData.ll_70, d.ll_70)
            const ul70Interp = d3.interpolate(this.oldData.ul_70, d.ul_70)
            return function(t) {
              const ul70 = valueToGaugeCoord(ul70Interp(t), radius)
              const ll70 = valueToGaugeCoord(ll70Interp(t), radius)
              const move = `M${ll70.x} ${ll70.y}`
              const arc = `A${radius} ${radius} 0 0 1 ${ul70.x} ${ul70.y}`
              return `${move} ${arc}`
            }
          })

      svg.selectAll("path.ci50")
        .data([this.data])
        .join("path")
          .attr("class", "ci50")
          .attr("stroke", this.colors.BLUE)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth)
          .transition()
          .attr("d", d => {
            const ul50 = valueToGaugeCoord(d.ul_50, radius)
            const ll50 = valueToGaugeCoord(d.ll_50, radius)
            const move = `M${ll50.x} ${ll50.y}`
            const arc = `A${radius} ${radius} 0 0 1 ${ul50.x} ${ul50.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const ll50Interp = d3.interpolate(this.oldData.ll_50, d.ll_50)
            const ul50Interp = d3.interpolate(this.oldData.ul_50, d.ul_50)
            return function(t) {
              const ul50 = valueToGaugeCoord(ul50Interp(t), radius)
              const ll50 = valueToGaugeCoord(ll50Interp(t), radius)
              const move = `M${ll50.x} ${ll50.y}`
              const arc = `A${radius} ${radius} 0 0 1 ${ul50.x} ${ul50.y}`
              return `${move} ${arc}`
            }
          })

      const progressionModeGroup = svg.selectAll("g.progression-mode")
        .data([{opacity: this.mode == 'progression' ? 1 : 0}])
        .join("g")
          .attr("class", "progression-mode")

      progressionModeGroup
        .transition()
        .attr("opacity", d => d.opacity)

      progressionModeGroup.selectAll("circle.past-day-gauge")
        .data([1, 2, 3])
        .join("circle")
          .attr("class", "past-day-gauge")
          .attr("stroke", this.colors.LIGHT_GRAY)
          .attr("stroke-width", strokeWidth / 2.0)
          .attr("fill", "none")
          .attr("cx", center)
          .attr("cy", center)
          .attr("r", d => radius - strokeWidth - strokeWidth * (d-0.5))
          .attr(
            "stroke-dasharray",
            d => {
              const c = (radius - strokeWidth - strokeWidth * (d-0.5)) * 2 * Math.PI
              return `${c*270/360} ${c*90/360}`
            }
          )
          .attr("transform", `rotate(135 ${center} ${center})`)

      progressionModeGroup.selectAll("path.past-ci70")
        .data(d3.zip(this.data.ll_70s, this.data.ul_70s).map(
          ([ll, ul], index) => {
            const color = d3.hsl(this.colors.LIGHT_BLUE)
            color.s = 0.3 + index * 0.18
            const r = radius - strokeWidth - strokeWidth * (2.5 - index)
            return {index, ll, ul, radius: r, color}
          }
        ))
        .join("path")
          .attr("class", "past-ci70")
          .attr("stroke", d => d.color)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth / 2.0)
          .transition()
          .attr("d", d => {
            const ul = valueToGaugeCoord(d.ul, d.radius)
            const ll = valueToGaugeCoord(d.ll, d.radius)
            const move = `M${ll.x} ${ll.y}`
            const arc = `A${d.radius} ${d.radius} 0 0 1 ${ul.x} ${ul.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const llInterp = d3.interpolate(this.oldData.ll_70s[d.index], d.ll)
            const ulInterp = d3.interpolate(this.oldData.ul_70s[d.index], d.ul)
            return function(t) {
              const ul = valueToGaugeCoord(ulInterp(t), d.radius)
              const ll = valueToGaugeCoord(llInterp(t), d.radius)
              const move = `M${ll.x} ${ll.y}`
              const arc = `A${d.radius} ${d.radius} 0 0 1 ${ul.x} ${ul.y}`
              return `${move} ${arc}`
            }
          })

      progressionModeGroup.selectAll("path.past-ci50")
        .data(d3.zip(this.data.ll_50s, this.data.ul_50s).map(
          ([ll, ul], index) => {
            const r = radius - strokeWidth - strokeWidth * (2.5 - index)
            const color = d3.hsl(this.colors.BLUE)
            color.s = 0.3 + index * 0.18
            return {index, ll, ul, radius: r, color}
          }
        ))
        .join("path")
          .attr("class", "past-ci50")
          .attr("stroke", d => d.color)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth / 2.0)
          .transition()
          .attr("d", d => {
            const ul50 = valueToGaugeCoord(d.ul, d.radius)
            const ll50 = valueToGaugeCoord(d.ll, d.radius)
            const move = `M${ll50.x} ${ll50.y}`
            const arc = `A${d.radius} ${d.radius} 0 0 1 ${ul50.x} ${ul50.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const ll50Interp = d3.interpolate(this.oldData.ll_50s[d.index], d.ll)
            const ul50Interp = d3.interpolate(this.oldData.ul_50s[d.index], d.ul)
            return function(t) {
              const ul50 = valueToGaugeCoord(ul50Interp(t), d.radius)
              const ll50 = valueToGaugeCoord(ll50Interp(t), d.radius)
              const move = `M${ll50.x} ${ll50.y}`
              const arc = `A${d.radius} ${d.radius} 0 0 1 ${ul50.x} ${ul50.y}`
              return `${move} ${arc}`
            }
          })

      progressionModeGroup.selectAll("path.spot")
        .data([this.data.value])
        .join("path")
          .attr("class", "spot")
          .attr("stroke", this.colors.BLACK)
          .attr("stroke-width", radius / 30.0)
          .transition()
          .attr("d", d => {
            const p1 = valueToGaugeCoord(d, radius - strokeWidth / 2.0)
            const p2 = valueToGaugeCoord(d, radius + strokeWidth / 2.0)
            return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
          })
          .attrTween("d", d => {
            const interp = d3.interpolate(this.oldData.value, d)
            return function(t) {
              const p1 = valueToGaugeCoord(interp(t), radius - strokeWidth / 2.0)
              const p2 = valueToGaugeCoord(interp(t), radius + strokeWidth / 2.0)
              return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
            }
          })

      progressionModeGroup.selectAll("path.past-spot")
        .data(this.data.values.map(
            (value, index) => {
              const r = radius - strokeWidth - strokeWidth * (2.5 - index)
              return {value, index, radius: r}
            }
        ))
        .join("path")
          .attr("class", "past-spot")
          .attr("stroke", this.colors.BLACK)
          .attr("stroke-width", radius / 30.0)
          .transition()
          .attr("d", d => {
            const p1 = valueToGaugeCoord(d.value, d.radius - strokeWidth / 4.0)
            const p2 = valueToGaugeCoord(d.value, d.radius + strokeWidth / 4.0)
            return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
          })
          .attrTween("d", d => {
            const interp = d3.interpolate(this.oldData.values[d.index], d.value)
            return function(t) {
              const p1 = valueToGaugeCoord(interp(t), d.radius - strokeWidth / 4.0)
              const p2 = valueToGaugeCoord(interp(t), d.radius + strokeWidth / 4.0)
              return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
            }
          })

      progressionModeGroup.selectAll("text.trend")
        .data(['Indicator Trend'])
        .join("text")
          .attr("class", "trend")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-size", size / 20.0)
          .attr("fill", this.colors.BLACK)
          .attr("x", center)
          .attr("y", center)
          .text(d => d)

      progressionModeGroup.selectAll("text.trend-arrow")
        .data([this.data.forecast > this.data.forecasts[0] ? {
          text: '▲', color: this.colors.GREEN
        } : {
          text: '▼', color: this.colors.RED
        }])
        .join("text")
          .attr("class", "trend-arrow")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-size", size / 10.0)
          .attr("x", center)
          .attr("y", center + strokeWidth * 1.5)
          .text(d => d.text)
          .transition()
          .attr("fill", d => d.color)


      progressionModeGroup.selectAll("text.past-days")
        .data(["Past 1-3 days"])
        .join("text")
          .attr("class", "past-days")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-weight", 500)
          .attr("fill", "grey")
          .attr("font-size", size / 20)
          .attr("x", center)
          .attr("y", center + radius / 1.8)
          .text(d => d)

      const forecastModeGroup = svg.selectAll("g.forecast-mode")
        .data([{opacity: this.mode == 'forecast' ? 1 : 0}])
        .join("g")
          .attr("class", "forecast-mode")

      forecastModeGroup
        .transition()
        .attr("opacity", d => d.opacity)

      forecastModeGroup.selectAll("circle.inner-gauge")
        .data([{}])
        .join("circle")
          .attr("class", "inner-gauge")
          .attr("stroke", this.colors.LIGHT_GRAY)
          .attr("stroke-width", strokeWidth / 2.0)
          .attr("fill", "none")
          .attr("cx", center)
          .attr("cy", center)
          .attr("r", innerGaugeRadius)

      forecastModeGroup.selectAll("text.ci50")
        .data([`${(this.data.ll_50/1000.0).toFixed(1)}k - ${(this.data.ul_50/1000.0).toFixed(1)}k`])
        .join("text")
          .attr("class", "ci50")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-size", size / 13.0)
          .attr("fill", this.colors.BLUE)
          .attr("font-weight", 900)
          .attr("x", center)
          .attr("y", center)
          .attr("dy", "0.4em")
          .text(d => d)

      forecastModeGroup.selectAll("text.spot-prices")
        .data(this.data.spots.length > 0 ? [this.data.averageSpot] : [])
        .join(
          function(enter) {
            return enter.append("text")
              .attr("opacity", 0)
          },
          function(update) { return update },
          function(exit) {
            return exit
              .transition()
              .attr("opacity", 0)
              .on('end', function() {
                d3.select(this).remove();
              });
          }
        )
          .attr("class", "spot-prices")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-size", size / 22.0)
          .attr("font-weight", 900)
          .attr("fill", this.colors.CAT4)
          .attr("x", center)
          .attr("y", center + strokeWidth*3)
          .text(d => `Avg. ${this.spotLabel}: ${(d/1000.0).toFixed(2)}k`)
          .transition()
          .attr("opacity", 1.0)


      const simpleRange = this.forecastRange == 'simple'
      forecastModeGroup.selectAll("text.forecast-range")
        .data([this.data.forecast > this.data.value ? {
          text: (simpleRange ? '' : 'Indicator Range ') + '▲',
          color: this.colors.GREEN
        } : {
          text: (simpleRange ? '' : 'Indicator Range ') + '▼',
          color: this.colors.RED
        }])
        .join("text")
        .attr("class", "forecast-range")
        .attr("text-anchor", "middle")
        .attr("font-family", "arial")
        .attr("font-size", size / (simpleRange ? 5.0 : 22.0))
        .attr("font-weight", 900)
        .attr("x", center)
        .attr("y", center + strokeWidth*(simpleRange? 3.6 : 1.8))
        .text(d => d.text)
        .transition()
        .attr("fill", d => d.color)

      const currentSpot = forecastModeGroup.selectAll("text.current-spot")
        .data([{
          tspans: [
            {weight: "normal", text: "Current "},
            {
              weight: "bold",
              text: `${(this.data.value/1000.0).toFixed(1)}k`
            }
          ]
        }])
        .join("text")
          .attr("class", "current-spot")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-size", size / 16.0)
          .attr("fill", this.colors.BLACK)
          .attr("x", center)
          .attr("y", center - strokeWidth*1.2)

      currentSpot.selectAll("tspan")
        .data(d => d.tspans)
        .join("tspan")
          .attr("font-weight", d => d.weight)
          .text(d => d.text)

      forecastModeGroup.selectAll("circle.spots")
        .data(this.data.spots)
        .join(
          function(enter) {
            return enter.append("circle")
              .attr("opacity", 0)
          },
          function(update) { return update },
          function(exit) {
            return exit
              .transition()
              .attr("opacity", 0)
              .on('end', function() {
                d3.select(this).remove();
              });
          }
        )
          .attr("class", "spots")
          .attr("stroke", "none")
          .attr("fill", this.colors.CAT4)
          .attr("r", (strokeWidth / 5))
          .attr("cx", d => valueToGaugeCoord(d, radius).x)
          .attr("cy", d => valueToGaugeCoord(d, radius).y)
          .transition()
          .attr("opacity", 0.9)

      forecastModeGroup.selectAll("path.spot-average")
        .data(this.data.averageSpot ? [this.data.averageSpot] : [])
        .join(
          function(enter) {
            return enter.append("path")
              .attr("opacity", 0)
          },
          function(update) { return update },
          function(exit) {
            return exit
              .transition()
              .attr("opacity", 0)
              .on('end', function() {
                d3.select(this).remove();
              });
          }
        )
          .attr("class", "spot-average")
          .attr("stroke", this.colors.CAT4)
          .attr("stroke-width", radius / 30.0)
          .transition()
          .attr("opacity", 0.9)
          .attr("d", d => {
            const p1 = valueToGaugeCoord(d, radius - strokeWidth / 2.0)
            const p2 = valueToGaugeCoord(d, radius + strokeWidth / 2.0)
            return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
          })
          .attrTween("d", d => {
            const interp = d3.interpolate(this.oldData.averageSpot || d, d)
            return function(t) {
              const p1 = valueToGaugeCoord(interp(t), radius - strokeWidth / 2.0)
              const p2 = valueToGaugeCoord(interp(t), radius + strokeWidth / 2.0)
              return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
            }
          })

      forecastModeGroup.selectAll("path.forecast")
        .data([{
          value: this.data.forecast,
          color: this.data.forecast > this.data.value ? this.colors.GREEN : this.colors.RED,
          offset: this.data.forecast > this.data.value ? deg2rad(4.5) : deg2rad(-4.5)
        }])
        .join("path")
          .attr("class", "forecast")
          .attr("stroke-width", radius / 30.0)
          .transition()
          .attr("stroke", d => d.color)
          .attr("fill", d => d.color)
          .attr("d", d => {
            const base = radius - strokeWidth / 2.0 - radius / 30.0
            const arrowSize = size / 30
            const p1 = valueToGaugeCoord(d.value, innerGaugeRadius - strokeWidth / 4.0)
            const p2 = valueToGaugeCoord(d.value, base)
            const p3 = valueToGaugeCoord(d.value, base - arrowSize / 2.0, d.offset)
            const p4 = valueToGaugeCoord(d.value, base - arrowSize)
            return `M${p1.x} ${p1.y} L${p2.x} ${p2.y} L${p3.x} ${p3.y} L${p4.x} ${p4.y}`
          })
          .attrTween("d", d => {
            const interp = d3.interpolate(this.oldData.forecast, d.value)
            return function(t) {
              const value = interp(t)
              const base = radius - strokeWidth / 2.0 - radius / 30.0
              const arrowSize = size / 30
              const p1 = valueToGaugeCoord(value, innerGaugeRadius - strokeWidth / 4.0)
              const p2 = valueToGaugeCoord(value, base)
              const p3 = valueToGaugeCoord(value, base - arrowSize / 2.0, d.offset)
              const p4 = valueToGaugeCoord(value, base - arrowSize)
              return `M${p1.x} ${p1.y} L${p2.x} ${p2.y} L${p3.x} ${p3.y} L${p4.x} ${p4.y}`
            }
          })

      forecastModeGroup.selectAll("path.diff")
        .data([{
          low: Math.min(this.data.forecast, this.data.value),
          high: Math.max(this.data.forecast, this.data.value),
          color: this.data.forecast > this.data.value ? this.colors.GREEN : this.colors.RED,
        }])
        .join("path")
          .attr("class", "diff")
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth / 2.0)
          .transition()
          .attr("stroke", d => d.color)
          .attr("d", d => {
            const low = valueToGaugeCoord(d.low, innerGaugeRadius)
            const high = valueToGaugeCoord(d.high, innerGaugeRadius)
            const move = `M${low.x} ${low.y}`
            const arc = `A${innerGaugeRadius} ${innerGaugeRadius} 0 0 1 ${high.x} ${high.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const oldLow = Math.min(this.oldData.forecast, this.oldData.value)
            const oldHigh = Math.max(this.oldData.forecast, this.oldData.value)
            const lowInterp = d3.interpolate(oldLow, d.low)
            const highInterp = d3.interpolate(oldHigh, d.high)
            return function(t) {
              const low = valueToGaugeCoord(lowInterp(t), innerGaugeRadius)
              const high = valueToGaugeCoord(highInterp(t), innerGaugeRadius)
              const move = `M${low.x} ${low.y}`
              const arc = `A${innerGaugeRadius} ${innerGaugeRadius} 0 0 1 ${high.x} ${high.y}`
              return `${move} ${arc}`
            }
          })

      forecastModeGroup.selectAll("path.current")
        .data([this.data.value])
        .join("path")
          .attr("class", "current")
          .attr("stroke", this.colors.BLACK)
          .attr("stroke-width", radius / 30.0)
          .transition()
          .attr("d", d => {
            const p1 = valueToGaugeCoord(d, innerGaugeRadius - strokeWidth / 4.0)
            const p2 = valueToGaugeCoord(d, innerGaugeRadius + strokeWidth / 4.0)
            return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
          })
          .attrTween("d", d => {
            const interp = d3.interpolate(this.oldData.value, d)
            return function(t) {
              const p1 = valueToGaugeCoord(interp(t), innerGaugeRadius - strokeWidth / 4.0)
              const p2 = valueToGaugeCoord(interp(t), innerGaugeRadius + strokeWidth / 4.0)
              return `M${p1.x} ${p1.y} L${p2.x} ${p2.y}`
            }
          })
    },
  },
  mounted() {
    this.oldData = this.data
    this.$nextTick(this.draw)
  },
  updated() {
    this.$nextTick(this.draw)
  }
}
</script>
