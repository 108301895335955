<template>
  <div>
  </div>
</template>

<script>
import * as d3 from "d3"
import { colorsMixin } from '@/mixins'

export default {
  props: {
    data: {
      default: () => ({})
    },
    route: {
      default: 'Route',
      type: String
    },
    week: {
      default: 1,
      type: Number
    },
    canvasSize: {
      default: 300,
      type: Number
    }
  },
  mixins: [ colorsMixin ],
  watch: {
    data(newData, oldData) {
      this.oldData = oldData
      this.$nextTick(this.draw)
    }
  },
  methods: {
    async draw() {
      const size = this.canvasSize * 0.8
      const strokeWidth = size / 18.0
      const center = this.canvasSize / 2.0
      const width = size - strokeWidth
      const margin = size * 0.15
      const daRadius = (width - margin) / 2.0
      const fcRadius = daRadius - strokeWidth * 1.5
      const rgRadius = fcRadius - strokeWidth * 1.5

      const toPctLabel = value => `${(value*100.0).toFixed(1)}%`
      const deg2rad = deg => (deg * Math.PI) / 180.0
      const gaugeScale = d3.scaleLinear()
        .domain([0.0, 1.0])
        .range([deg2rad(45), deg2rad(315)])

      const valueToGaugeCoord = (v, r) => {
        const x = center - r * Math.sin(gaugeScale(v))
        const y = center + r * Math.cos(gaugeScale(v))
        return {x, y}
      }

      let svg = d3.select(this.$el).select("svg")

      if (svg.empty()) {
        svg = d3.select(this.$el)
          .append("svg")
          .attr("width", this.canvasSize)
          .attr("height", this.canvasSize)
      }

      svg.selectAll("text.title")
        .data([`${this.route} W+${this.week}`])
        .join("text")
          .attr("class", "title")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-weight", 500)
          .attr("fill", this.colors.BLACK)
          .attr("font-size", size / 13.0)
          .attr("x", center)
          .attr("y", center + daRadius)
          .text(d => d)

      svg.selectAll("circle.gauge")
        .data([daRadius, fcRadius, rgRadius])
        .join("circle")
          .attr("class", "gauge")
          .attr("stroke", this.colors.LIGHT_GRAY)
          .attr("stroke-width", strokeWidth)
          .attr("fill", "none")
          .attr("cx", center)
          .attr("cy", center)
          .attr("r", d => d)
          .attr("stroke-dasharray", d => {
            const circumference = d * 2 * Math.PI
            return `${circumference*0.75} ${circumference*0.25}`
          })
          .attr("transform", `rotate(135 ${center} ${center})`)

      svg.selectAll("path.arc")
        .data([
          {radius: daRadius, key: 'directionalAccuracy'},
          {radius: fcRadius, key: 'forecastAccuracy'},
          {radius: rgRadius, key: 'rangeAccuracy'}
        ])
        .join("path")
          .attr("class", "arc")
          .attr("stroke-width", strokeWidth)
          .attr("fill", "none")
          .transition()
          .attr("stroke", d => this.data[d.key] > 0.2 ? this.colors.GREEN : this.colors.RED)
          .attr("d", d => {
            const value = this.data[d.key]
            const start = valueToGaugeCoord(0, d.radius)
            const end = valueToGaugeCoord(value, d.radius)
            const big = value >= 1.0 / 270 * 180 ? 1 : 0
            const move = `M${start.x} ${start.y}`
            const arc = `A${d.radius} ${d.radius} 0 ${big} 1 ${end.x} ${end.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const value = this.data[d.key]
            const endInterp = d3.interpolate(this.oldData[d.key] || value, value)
            return function(t) {
              const start = valueToGaugeCoord(0, d.radius)
              const end = valueToGaugeCoord(endInterp(t), d.radius)
              const big = endInterp(t) >= 1.0 / 270 * 180 ? 1 : 0
              const move = `M${start.x} ${start.y}`
              const arc = `A${d.radius} ${d.radius} 0 ${big} 1 ${end.x} ${end.y}`
              return `${move} ${arc}`
            }
          })


      svg.selectAll("circle.marker")
        .data([
          {
            radius: daRadius,
            key: 'directionalAccuracy',
            color: this.colors.CAT3
          },
          {
            radius: fcRadius,
            key: 'forecastAccuracy',
            color: this.colors.CAT8
          },
          {
            radius: rgRadius,
            key: 'rangeAccuracy',
            color: this.colors.CAT5
          }
        ])
        .join("circle")
          .attr("class", "marker")
          .attr("stroke", "white")
          .attr("stroke-width", size * 0.014)
          .attr("fill", d => d.color)
          .attr("r", (strokeWidth + size * 0.01) / 2.0)
          .transition()
          .attr("cx", d => valueToGaugeCoord(this.data[d.key], d.radius).x)
          .attrTween("cx", d => {
            const value = this.data[d.key]
            const valueInterp = d3.interpolate(this.oldData[d.key] || value, value)
            return function(t) {
              return valueToGaugeCoord(valueInterp(t), d.radius).x
            }
          })
          .attr("cy", d => valueToGaugeCoord(d.value, d.radius).y)
          .attrTween("cy", d => {
            const value = this.data[d.key]
            const valueInterp = d3.interpolate(this.oldData[d.key] || value, value)
            return function(t) {
              return valueToGaugeCoord(valueInterp(t), d.radius).y
            }
          })

      svg.selectAll("text.accuracy")
        .data([toPctLabel(this.data.accuracy)])
        .join("text")
          .attr("class", "accuracy")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-weight", 500)
          .attr("fill", this.colors.BLACK)
          .attr("font-size", size / 13.0)
          .attr("x", center)
          .attr("y", center - size / 13.0)
          .text(d => d)

      const accuracies = svg.selectAll("text.accuracies")
        .data([
          {index: 0, label: toPctLabel(this.data.directionalAccuracy), color: this.colors.CAT3},
          {index: 1, label: toPctLabel(this.data.forecastAccuracy), color: this.colors.CAT8},
          {index: 2, label: toPctLabel(this.data.rangeAccuracy), color: this.colors.CAT5},
        ])
        .join("text")
          .attr("class", "accuracies")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-weight", 500)
          .attr("fill", this.colors.BLACK)
          .attr("font-size", size / 18.0)
          .attr("x", center)
          .attr("y", d => center + (size / 16.0 * d.index))

      accuracies.selectAll("tspan")
          .data(d => [
            {label: '● ', color: d.color},
            {label: d.label, color: "#777777"}
          ])
          .join("tspan")
            .attr("fill", d => d.color)
            .text(d => d.label)
    }
  },
  mounted() {
    this.oldData = this.data
    this.$nextTick(this.draw)
  },
  updated() {
    this.$nextTick(this.draw)
  }
}
</script>
