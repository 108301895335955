<template>
  <b-table
    :items="items"
    :fields="fields"
    small
    hover
    empty-text="No Data Available"
    show-empty
    >
    <template v-slot:cell(commodity)="data">
      {{ ppCommodity(data.item.commodity) }}
    </template>
    <template v-slot:cell(tennor)="data">
      {{ ppContract(data.item.tennor) }}
    </template>
    <template v-slot:cell(action)="data">
      {{ ppAction(data.item.action) }}
    </template>
    <template v-slot:cell(type)="data">
      {{ ppTradeType(data.item.type) }}
    </template>
    <template v-slot:cell(net_lots)="data">
      {{ ppFloat(data.item.net_lots) }}
    </template>
    <template v-slot:cell(net_d)="data">
      {{ ppFloat(data.item.net_d) }}
    </template>
    <template v-slot:cell(m2m)="data">
      <span :class="{ 'text-danger': Number(data.item.m2m) < 0 }">
        {{ format(data.item.m2m) }}
      </span>
    </template>
  </b-table>
</template>

<script>
import { ppMixin, tableMixin } from '@/mixins'

export default {
  props: ['data'],
  mixins: [ppMixin, tableMixin],
  data: function() {
    return {
      fields: [
        {key: 'tennor', label: 'Contract', sortable: true},
        {key: 'commodity', label: 'Commodity', sortable: true},
        {key: 'trade_entry', label: 'Entry Date', sortable: true},
        {key: 'action', label: 'Action', sortable: true},
        {key: 'type', label: 'Trade Type', sortable: true},
        {key: 'premium_exposure', label: 'Premium Exposure', sortable: true, class: 'text-right'},
        {key: 'margin_exposure', label: 'Margin Exposure', sortable: true, class: 'text-right'},
        {key: 'strike', label: 'Strike', sortable: true, class: 'text-right'},
        {key: 'price', label: 'Price', sortable: true, class: 'text-right'},
        {key: 'net_lots', label: 'Net Lots', sortable: true, class: 'text-right'},
        {key: 'net_d', label: 'Net Delta', sortable: true, class: 'text-right'},
        {key: 'm2m', label: 'Mark-to-Market', sortable: true, class: 'text-right'},
      ],
    }
  },
  computed: {
    items() {
      return this.data.toCollection().map(item => {
        for (let c of ['premium_exposure', 'margin_exposure', 'strike', 'price']) {
          item[c] = this.hideZeroFormat(item[c])
        }
        return item
      })
    },
  }
}
</script>
