<template>
  <div>
    <h5>Realized Positions 12 Months Rolling</h5>
    <b-table-simple small hover caption-top>
      <b-thead>
        <b-tr>
          <b-th>Contract</b-th>
          <b-th>Commodity</b-th>
          <b-th>Trade Type</b-th>
          <b-th class="text-right">Premium Exposure</b-th>
          <b-th class="text-right">Margin Exposure</b-th>
          <b-th class="text-right">Avg. Strike</b-th>
          <b-th class="text-right">Avg. Price</b-th>
          <b-th class="text-right">Net Lots</b-th>
          <b-th class="text-right">Net Profit</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="item in collection"
          v-bind:key="itemKey(item)">
          <b-td>{{ ppContract(item.tennor) }}</b-td>
          <b-td>{{ ppCommodity(item.commodity) }}</b-td>
          <b-td>{{ ppTradeType(item.type) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.premium_exposure) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.margin_exposure) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.strike) }}</b-td>
          <b-td class="text-right">{{ hideZeroFormat(item.price) }}</b-td>
          <b-td class="text-right">{{ ppFloat(item.net_lots) }}</b-td>
          <b-td class="text-right">
            <span :class="{ 'text-danger': item.pnl_trade < 0 }">
              {{ format(item.pnl_trade) }}
            </span>
          </b-td>
        </b-tr>
        <b-tr v-if="data.count() == 0">
          <b-td colspan="9">
            <b-alert class="text-center" variant="default" show>No Data Available</b-alert>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot v-if="data.count() > 0">
        <b-tr>
          <b-th>Total</b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th class="text-right">{{ hideZeroFormat(sums['premium_exposure']) }}</b-th>
          <b-th class="text-right">{{ hideZeroFormat(sums['margin_exposure']) }}</b-th>
          <b-th class="text-right">{{ format(averages['strike']) }}</b-th>
          <b-th class="text-right">{{ format(averages['price']) }}</b-th>
          <b-th class="text-right">{{ ppFloat(sums['net_lots']) }}</b-th>
          <b-th class="text-right">
            <span :class="{ 'text-danger': sums['pnl_trade'] < 0 }">
              {{ format(sums['pnl_trade']) }}
            </span>
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import { ppMixin, tableMixin } from '@/mixins'

export default {
  props: ['data'],
  mixins: [ppMixin, tableMixin]
}
</script>
