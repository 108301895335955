<template>
  <b-table-simple>
    <b-tr>
      <b-th>Commodity</b-th>
      <b-th>Contract</b-th>
      <b-th>Instrument</b-th>
      <b-th>Actions</b-th>
      <b-th class="text-right">Lots</b-th>
      <b-th class="text-right">Real Strike / Price</b-th>
      <b-th class="text-right">Real Paid / Margin</b-th>
      <b-th>Date</b-th>
    </b-tr>
    <b-tr v-for="recent in recentPositions" v-bind:key="recent.id">
      <b-td>{{ ppCommodity(recent.commodity) }}</b-td>
      <b-td>{{ "M"+recent.contract }}</b-td>
      <b-td>{{ ppTradeType(recent.type) }}</b-td>
      <b-td>{{ ppAction(recent.action) }}</b-td>
      <b-td class="text-right">{{ recent.lots }}</b-td>
      <b-td class="text-right">{{ format(strikeOrPrice(recent)) }}</b-td>
      <b-td class="text-right">{{ format(recent.paid) }}</b-td>
      <b-td>{{ recent.trade_entry }}</b-td>
    </b-tr>
    <b-tr v-if="recentPositions.length == 0">
      <b-td colspan="8">
        <b-alert class="text-center" variant="default" show>No Data Available</b-alert>
      </b-td>
    </b-tr>
  </b-table-simple>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  name: 'RecentPositionsTable',
  mixins: [ppMixin],
  methods: {
    strikeOrPrice(recent) {
      if (recent.type == "ffa") {
        return recent.price
      }
      return recent.strike
    },
  },
  computed: {
    recentPositions() {
      const trading_ctx = this.$store.getters.tradingContext;
      if (trading_ctx) {
        return trading_ctx.recent_trades
      }
      return []
    }
  }
}
</script>
