<template>
  <b-form @submit="onSubmit" @reset="onReset">
    <b-table-simple>
      <b-tr>
        <b-th>Commodity</b-th>
        <b-th>Contract</b-th>
        <b-th>Instrument</b-th>
        <b-th>Action</b-th>
        <b-th class="text-right">Lots</b-th>
        <b-th class="text-right">Trigger Strike</b-th>
        <b-th class="text-right">Trigger Price</b-th>
        <b-th class="text-right">Trigger Paid</b-th>
        <b-th class="text-right">Trigger Maint. Margin</b-th>
        <b-th></b-th>
      </b-tr>
      <b-tr v-for="trade in $store.getters.potentialTrades" :key="trade.id">
        <b-td>{{ ppCommodity(trade.commodity) }}</b-td>
        <b-td>{{ trade.contract }}</b-td>
        <b-td>{{ ppTradeType(trade.type) }}</b-td>
        <b-td>{{ ppAction(trade.action) }}</b-td>
        <b-td class="text-right">{{ trade.lots }}</b-td>
        <b-td class="text-right">{{ trade.trigger_strike && format(trade.trigger_strike) }}</b-td>
        <b-td class="text-right">{{ trade.trigger_price && format(trade.trigger_price) }}</b-td>
        <b-td class="text-right">{{ trade.trigger_paid && format(trade.trigger_paid) }}</b-td>
        <b-td class="text-right">{{ trade.trigger_maint_margin && format(trade.trigger_maint_margin) }}</b-td>
        <b-td>
          <b-button size="sm" variant="primary" v-on:click="prefill(trade)">Prefill</b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
    <b-row>
      <b-col>
        <b-form-group label="Commodity" label-for="commodity-select">
          <b-form-select
            id="commodity-select"
            size="sm"
            v-model="form.commodity"
            :options="commodityOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Contract" label-for="contract-select">
          <b-form-select
            id="contract-select"
            size="sm"
            v-model="form.contract"
            :options="contractOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Instument" label-for="instrument-select">
          <b-form-select
            id="instrument-select"
            size="sm"
            v-model="form.type"
            :options="typeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Action" label-for="action-select">
          <b-form-select
            id="action-select"
            size="sm"
            v-model="form.action"
            :options="actionOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Lots" label-for="lots-input">
          <b-form-input
            id="lots-input"
            size="sm"
            type="number"
            v-model="form.lots"
            placeholder="Enter Lots"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="form.type == 'put' || form.type == 'call'">
        <b-form-group label="Requested Strike" label-for="trigger-strike-input">
          <b-form-input
            id="trigger-strike-input"
            size="sm"
            type="number"
            v-model="form.trigger_strike"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="form.type != null">
        <b-form-group label="Requested Price" label-for="trigger-price-input">
          <b-form-input
            id="trigger-price-input"
            size="sm"
            type="number"
            v-model="form.trigger_price"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="form.type != null">
        <b-form-group label="Requested Paid" label-for="trigger-paid-input">
          <b-form-input
            id="trigger-paid-input"
            size="sm"
            type="number"
            v-model="form.trigger_paid"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="form.type == 'ffa'">
        <b-form-group label="Trigger Maint Margin" label-for="trigger-maint-margin">
          <b-form-input
            id="trigger-maint-margin"
            size="sm"
            type="number"
            v-model="form.trigger_maint_margin"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="form.commodity">
        <b-form-group label="Trade Reference" label-for="trade-ref">
          <b-form-select
            id="trade-ref"
            size="sm"
            v-model="form.trade_ref"
            :options="tradeRefOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-button
            class="mr-2"
            size="sm"
            type="submit"
            variant="primary"
            :disabled="!canCreate">
            Create
          </b-button>
          <b-button size="sm" type="reset" variant="danger">Reset</b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  name: 'NewPositionForm',
  mixins: [ppMixin],
  data() {
    return {
      form: {
        commodity: null,
        contract: null,
        type: null,
        action: null,
        lots: null,
        trigger_strike: null,
        trigger_price: null,
        trigger_paid: null,
        trigger_maint_margin: null,
        trade_ref: ''
      },
      typeOptions: [
        { value: null, text: 'Select One' },
        { value: 'call', text: 'Call Option' },
        { value: 'put', text: 'Put Option' },
        { value: 'ffa', text: 'FFA' },
      ],
      actionOptions: [
        { value: null, text: 'Select One' },
        { value: 'buy', text: 'Buy' },
        { value: 'sell', text: 'Sell' },
      ]
    }
  },
  computed: {
    canCreate() {
      return (
        this.form.contract != null &&
        this.form.type != null &&
        this.form.action != null &&
        this.form.trigger_price != null &&
        this.form.lots != null &&
        ((this.form.type == 'ffa' &&
          this.form.trigger_maint_margin != null &&
          this.form.trigger_paid != null) ||
         ((this.form.type == 'put' || this.form.type == 'call') &&
          this.form.trigger_strike != null &&
          this.form.trigger_paid != null))
      )
    },
    commodityOptions() {
      const options = [
        { value: null, text: 'Select One' }
      ]
      this.$store.state.commodities.forEach(c => {
        options.push({ value: c.name, text: c.displayName })
      })
      return options
    },
    contractOptions() {
      const options = [
        { value: null, text: 'Select One' }
      ]
      const commodity = this.$store.state.commodities.find(c => c.name == this.form.commodity)
      if (commodity) {
        commodity.contracts.forEach(c => {
          options.push({ value: c, text: c })
        })
      }
      return options
    },
    tradeRefOptions() {
      const options = [
        { value: '', text: '' }
      ]
      const commodity = this.form.commodity.toLowerCase()
      this.$store.getters.tradeRefs.forEach(function(ref) {
        if (ref.includes(commodity)) {
          options.push({value: ref, text: ref})
        }
      })
      return options
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      console.log("form", this.form);
      this.$store.dispatch("new_trade", this.form)
    },
    onReset(evt) {
      evt.preventDefault()
      this.resetForm()
    },
    resetForm() {
      this.form.commodity = null
      this.form.contract = null
      this.form.type = null
      this.form.action = null
      this.form.lots = null
      this.form.trigger_strike = null
      this.form.trigger_price = null
      this.form.trigger_paid = null
      this.form.trigger_maint_margin = null
      this.form.trade_ref = ''
    },
    prefill(trade) {
      this.form.commodity = trade.commodity
      this.form.contract = trade.contract
      this.form.type = trade.type
      this.form.action = trade.action
      this.form.lots = trade.lots
      this.form.trigger_strike = trade.trigger_strike
      this.form.trigger_price = trade.trigger_price
      this.form.trigger_paid = trade.trigger_paid
      this.form.trigger_maint_margin = trade.trigger_maint_margin
    }
  },
  watch: {
    'form.commodity': function(value) {
      if (value == null) {
        this.resetForm()
      }
    },
    'form.type': function(value) {
      if (value == 'ffa') {
        this.form.trigger_strike = null
      }
      if (value == 'put' || value == 'call') {
        this.form.trigger_maint_margin = null
      }
    }
  }
}
</script>
