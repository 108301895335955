<template>
  <div>
    <b-container fluid>
      <b-row
       v-for="(row, index) in forecastRows"
       v-bind:key="row.name"
      >
        <b-col
          sm="2"
          v-for="(col, colIx) in row.cols"
          v-bind:key="col.name"
          v-bind:class="{'bg-light': (index & 1 && colIx < 3) || (index % 2 == 0 & colIx > 2)}"
        >
          <D3RouteForecast
            :ref="col.name"
            v-bind:data="col.d3ForecastData"
            :week="col.week"
            :route="col.label"
            mode="forecast"
            :canvasSize="200"
            range="static"
            forecastRange="simple"
            spotLabel="Price"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import D3RouteForecast from '@/components/D3RouteForecast.vue'
import { colorsMixin } from '@/mixins'

export default {
  props: ['data'],
  components: {
    D3RouteForecast,
    //D3RouteFeatures,
    //D3RoutePerformance
  },
  mixins: [ colorsMixin ],
  computed: {
    forecastRows() {
      if (!this.data || Object.keys(this.data.forecasts).length == 0) {
        return []
      }
      return [
        {
          'name': 'atlantic_routes',
          'cols': [
            this.data.forecasts['P1A_82_proxy_W4'],
            this.data.forecasts['P1A_82_proxy_W5'],
            this.data.forecasts['P1A_82_proxy_W6'],
            this.data.forecasts['P2A_82_proxy_W4'],
            this.data.forecasts['P2A_82_proxy_W5'],
            this.data.forecasts['P2A_82_proxy_W6'],
          ]
        },
        {
          'name': 'pacific_routes',
          'cols': [
            this.data.forecasts['P3A_82_proxy_W4'],
            this.data.forecasts['P3A_82_proxy_W5'],
            this.data.forecasts['P3A_82_proxy_W6'],
            this.data.forecasts['P6_82_W4'],
            this.data.forecasts['P6_82_W5'],
            this.data.forecasts['P6_82_W6'],
          ]
        },
        {
          'name': 'panamax',
          'cols': [
            this.data.forecasts['pmx_M1_roll'],
            this.data.forecasts['pmx_M2_roll'],
            this.data.forecasts['pmx_M3_roll'],
            this.data.forecasts['pmx_Q2'],
            this.data.forecasts['pmx_Q3'],
            this.data.forecasts['pmx_Q4'],
          ]
        },
        {
          'name': 'caepesize',
          'cols': [
            this.data.forecasts['capes_M1_roll'],
            this.data.forecasts['capes_M2_roll'],
            this.data.forecasts['capes_M3_roll'],
            this.data.forecasts['capes_Q2'],
            this.data.forecasts['capes_Q3'],
            this.data.forecasts['capes_Q4'],
          ]
        }
      ]
    }
  }
}
</script>
