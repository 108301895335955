<template>
  <b-tr>
    <b-th>{{ title }}</b-th>
    <b-td class="text-right">{{ format(data.cumulativeNetProfit) }}</b-td>
    <b-td class="text-right">{{ Number(data.profitLossRatio).toFixed(2) }}:1</b-td>
    <b-td class="text-right">{{ format(data.averageNetProfitPerTrade) }}</b-td>
    <b-td class="text-right">{{ Number(data.averageTradeDuration).toFixed(2) }}</b-td>
    <b-td class="text-right">{{ data.count }}</b-td>
    <b-td class="text-right">{{ format(data.averageVaR) }}</b-td>
    <b-td class="text-right">{{ format(data.maxVaR) }}</b-td>
    <b-td class="text-right">{{ data.sharpeRatio ? Number(data.sharpeRatio).toFixed(2) : ''}}</b-td>
    <b-td class="text-right">{{ data.sortinoRatio ? Number(data.sortinoRatio).toFixed(2) : ''}}</b-td>
  </b-tr>
</template>


<script>
import { ppMixin } from '@/mixins'

export default {
  name: 'RealizedRow',
  props: {
    title: String,
    data: Object,
  },
  mixins: [ppMixin],
}
</script>
