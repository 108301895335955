<template>
  <b-card no-body>
    <b-card-body class="pb-0" body-bg-variant="light">
      <b-card-title>Open Trades</b-card-title>
      <b-card-text>
        <b-table-simple small>
          <b-tbody>
            <b-tr v-if="!commodity">
              <b-th>VaR 95 (1 day)</b-th>
              <b-td class="text-right">{{ format(data.valueAtRisk) }}</b-td>
            </b-tr>
            <b-tr v-if="!commodity && data.expectedShortfall">
              <b-th>Expected Shortfall</b-th>
              <b-td class="text-right">{{ format(data.expectedShortfall) }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Mark-to-Market</b-th>
              <b-td class="text-right">{{ format(data.markToMarket) }}</b-td>
            </b-tr>
            <b-tr v-if="data.partiallyRealized">
              <b-th>Partially Realized</b-th>
              <b-td class="text-right">{{ format(data.partiallyRealized) }}</b-td>
            </b-tr>
            <b-tr v-if="data.premiumExposure">
              <b-th>Premium Exposure</b-th>
              <b-td class="text-right">{{ format(data.premiumExposure) }}</b-td>
            </b-tr>
            <b-tr v-if="data.marginExposure">
              <b-th>Margin Exposure</b-th>
              <b-td class="text-right">{{ format(data.marginExposure) }}</b-td>
            </b-tr>
            <b-tr v-if="commodity">
              <b-th>Net Delta</b-th>
              <b-td class="text-right">{{ Number(data.delta).toFixed(2) }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Num. of Trades</b-th>
              <b-td class="text-right">{{ data.count }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  name: 'OpenTradesCard',
  props: {
    data: Object,
    commodity: {type: Boolean, default: false}
  },
  mixins: [ppMixin],
}
</script>
