<template>
  <div class="text-center">
    <!-- <b-spinner label="Loading..."></b-spinner> -->
  </div>
</template>
<script>
export default {
  name: 'NotAsked'
}
</script>
