<template>
  <b-col sm="4">
    <b-card style="min-width: 20rem;" :class="data.classes">
      <template v-if="true">
        <ContractHeader v-bind:month="data.month" v-bind:date="data.date" />
        <b-card-text>
          <ContractSummary v-bind:data="data" />
          <b-table-simple small>
            <b-tbody>
              <b-tr>
                <b-th>Blocked due to</b-th>
                <b-td class="text-right">{{ ppBlocked(data.blocked) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <div class="text-center"><strong>FFA Margins</strong></div>
          <b-table-simple small>
            <b-tbody>
              <b-tr>
                <b-th>Initial Margin</b-th>
                <b-td>{{ data.initialMargin }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Maintenance Margin</b-th>
                <b-td>{{ data.maintenanceMargin }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="text-center"><strong>Options Strike Prices</strong></div>
          <b-table-simple small>
            <b-tbody>
              <b-tr>
                <b-th></b-th>
                <b-th>U1</b-th>
                <b-th>U2</b-th>
                <b-th>U3</b-th>
              </b-tr>
              <b-tr>
                <b-th>Strike Price</b-th>
                <b-td>{{ data.U1StrikePrice }}</b-td>
                <b-td>{{ data.U2StrikePrice }}</b-td>
                <b-td>{{ data.U3StrikePrice }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Option Premium</b-th>
                <b-td>{{ data.U1Premium }}</b-td>
                <b-td>{{ data.U2Premium }}</b-td>
                <b-td>{{ data.U3Premium }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <Plotly
            v-bind:data="data.payoffGraph"
            v-bind:layout="payoffLayout"
            style="width: 100%; height: 150px"
            :displaylogo="false"
            />
          <div class="text-center"><strong>Market Prices</strong></div>
          <Plotly
            v-bind:data="data.marketPricesGraph"
            :layout="defaultLayout"
            style="width: 100%; height: 150px"
            :displaylogo="false"
            />
          <b-table-simple small>
            <b-tbody>
              <b-tr>
                <b-th>CV Value</b-th>
                <b-td class="text-right">{{ data.CV.toFixed(2) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <Plotly
            v-bind:data="data.CVGraph"
            :layout="defaultLayout"
            style="width: 100%; height: 150px"
            :displaylogo="false"
            />
          <b-table-simple small>
            <b-tbody>
              <b-tr>
                <b-th>Implied Volatility</b-th>
                <b-td class="text-right">{{ data.impliedVolatility.toFixed(2) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple v-if="$store.getters.isTBI" small>
            <b-tbody>
              <b-tr>
                <b-th></b-th>
                <b-th
                  v-for="engine in data.enginesData"
                  v-bind:key="engine.name"
                  class="text-right">
                {{ engine.name }}
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>Recommendation</b-th>
                <b-td
                  v-for="engine in data.enginesData"
                  v-bind:key="engine.name"
                  class="text-right">
                  {{ engine.recommendation }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>Forecast</b-th>
                <b-td
                  v-for="engine in data.enginesData"
                  v-bind:key="engine.name"
                  class="text-right">
                  {{ engine.forecast }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>Gap %</b-th>
                <b-td
                  v-for="engine in data.enginesData"
                  v-bind:key="engine.name"
                  class="text-right">
                  {{ engine.gap }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card-text>
      </template>
      <template v-else>
        <b-card-title>{{ contract.name }}</b-card-title>
        <b-card-text>
          <div v-if="contract.no_data"><h4>No Data Available</h4></div>
          <div v-else class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </b-card-text>
      </template>
    </b-card>
  </b-col>
</template>

<script>
import { Plotly } from '@pankgeorg/vue-plotly'
import ContractHeader from '@/components/ContractHeader.vue'
import ContractSummary from '@/components/ContractSummary.vue'
import { ppMixin } from '@/mixins'

export default {
  name: 'ContractDetailedCard',
  props: ['data'],
  mixins: [ppMixin],
  components: {
    Plotly,
    ContractHeader,
    ContractSummary
  },
  computed: {
    defaultLayout() {
      return {
        margin: {t: 0, b: 30, l: 20, r: 0},
        legend: {
          xanchor: 'right',
          bgcolor: 'rgba(255, 255, 255, 0.6)'
        }
      }
    },
    payoffLayout() {
      const data = this.data;
      return {
        margin: {t: 0, b:30, l: 35, r: 0},
        legend: {
          xanchor: 'right',
          bgcolor: "rgba(255, 255, 255, 0.6)"
        },
        shapes: [
          {
            'type': 'line',
            'name': 'Market Price',
            'line': {
              'dash': 'dash',
              'color': 'red'
            },
            'yref': 'paper',
            'y0': 0,
            'y1': 1,
            'x0': data.marketPrice,
            'x1': data.marketPrice
          },
          {
            'type': 'line',
            'name': 'Spot Price',
            'line': {
              'dash': 'dash',
              'color': 'purple'
            },
            'yref': 'paper',
            'y0': 0,
            'y1': 1,
            'x0': data.spotPrice,
            'x1': data.spotPrice
          }
        ]
      };
    }
  }
}
</script>
