<template>
  <b-tr>
    <b-th
      class="align-middle text-center"
      :rowspan="numRows"
      v-if="showContractHeader"
    >
        <h4>{{ contract }}</h4>
    </b-th>
    <b-th>{{ label }}</b-th>
    <b-td class="text-right">{{ mainValue('capesize') }}</b-td>
    <b-td :class="diffClass('capesize')">{{ diffValue('capesize') }}</b-td>
    <b-td class="text-right">{{ mainValue('panamax') }}</b-td>
    <b-td :class="diffClass('panamax')">{{ diffValue('panamax') }}</b-td>
    <b-td class="text-right">{{ mainValue('total') }}</b-td>
    <b-td :class="diffClass('total')">{{ diffValue('total') }}</b-td>
  </b-tr>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  props: ['data', 'label', 'variable', 'contract', 'type', 'higherIsBetter', 'numRows'],
  mixins: [ppMixin],
  computed: {
    showContractHeader() {
      return typeof this.numRows != 'undefined'
    },
  },
  methods: {
    value(commodity) {
      if (commodity == 'total') {
        return this.value('capesize') + this.value('panamax')
      }
      return this.data.dayData[commodity + '_' + this.contract + '_' + this.variable]
    },
    prevValue(commodity) {
      if (commodity == 'total') {
        return this.prevValue('capesize') + this.prevValue('panamax')
      }
      return this.data.previousDayData[commodity + '_' + this.contract + '_' + this.variable]
    },
    mainValue(commodity) {
      return this.formatByType(this.value(commodity))
    },
    arrow(commodity) {
      if (this.value(commodity) == this.prevValue(commodity)) {
        return ' \uff1d'
      }
      if (this.value(commodity) > this.prevValue(commodity)) {
        return ' \u25b2'
      }
      return ' \u25bc'
    },
    diffValue(commodity) {
      return this.formatByType(
        this.value(commodity) - this.prevValue(commodity)
      ) + this.arrow(commodity)
    },
    diffClass(commodity) {
      const cls = {'text-right': true}
      if (this.value(commodity) > this.prevValue(commodity)) {
        cls[this.higherIsBetter ? 'text-success' : 'text-danger'] = true
      }
      if (this.value(commodity) < this.prevValue(commodity)) {
        cls[this.higherIsBetter ? 'text-danger' : 'text-success'] = true
      }
      return cls
    },
    formatByType(value) {
      if (this.type == 'financials') {
        return this.format(value)
      } else if (this.type == 'percentage') {
        return this.ppFloat(value * 100.0) + '%'
      } else if (this.type == 'float') {
        return this.ppFloat(value)
      }
      return value
    }
  }
}
</script>
