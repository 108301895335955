<template>
  <div>
    <b-container fluid>
      <b-row><h3>Portfolio</h3></b-row>
      <b-row v-if="data.openTradesCardsData">
        <b-col sm="3" class="mb-2">
          <OpenTradesCard v-bind:data="data.openTradesCardsData['portfolio']" />
        </b-col>
        <b-col sm="9" class="mb-2">
          <RealizedCard v-bind:data="data.realizedCardData" />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid v-for="commodity in commodities" v-bind:key="commodity.name">
      <b-row>
        <h3>{{ commodity.displayName }}</h3>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="3" class="mb-2">
          <OpenTradesCard commodity v-bind:data="data.openTradesCardsData[commodity.name]" />
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="3" class="mb-2"
          v-for="contract in commodity.contracts"
          v-bind:key="contract">
          <ContractCard v-bind:data="data.contractsData[commodity.name + '_' + contract]" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OpenTradesCard from '@/components/OpenTradesCard.vue'
import RealizedCard from '@/components/RealizedCard.vue'
import ContractCard from '@/components/ContractCard.vue'

export default {
  props: ['data', 'commodities'],
  components: {OpenTradesCard, RealizedCard, ContractCard}
}
</script>
