<template>
  <b-tab
    v-if="$store.getters.canViewForecasts"
    title="Forecasts"
    style="width: 100%"
    >
    <Plotly
      ref="forecasts"
      v-bind:data="data"
      v-bind:layout="graphLayout"
      style="height: 600px;"
      :displaylogo="false"
      />
  </b-tab>
</template>

<script>
import { Plotly } from '@pankgeorg/vue-plotly'

export default {
  props: ['data'],
  components: {Plotly},
  computed: {
    graphLayout() {
      let date = new Date();
      if (this.$store.state.current_date) {
        date = new Date(this.$store.state.current_date);
      }
      return {
        margin: {t: 20, b:40, l: 30, r: 0},
        legend: {
          xanchor: 'right',
          bgcolor: "rgba(255, 255, 255, 0.6)"
        },
        shapes: [
          {
            'type': 'line',
            'name': 'Today',
            'line': {
              'dash': 'dash',
              'color': 'rgba(100, 104, 99, 0.7)'
            },
            'yref': 'paper',
            'y0': 0,
            'y1': 1,
            'x0': date,
            'x1': date
          }
        ]
      }
    }
  }
}
</script>
