<template>
  <b-table
    :items="items"
    :fields="fields"
    empty-text="No Data Available"
    show-empty
    small
    hover
    >
    <template v-slot:cell(commodity)="data">
      {{ ppCommodity(data.item.commodity) }}
    </template>
    <template v-slot:cell(tennor)="data">
      {{ ppContract(data.item.tennor) }}
    </template>
    <template v-slot:cell(action)="data">
      {{ ppAction(data.item.action) }}
    </template>
    <template v-slot:cell(type)="data">
      {{ ppTradeType(data.item.type) }}
    </template>
    <template v-slot:cell(irr)="data">
      <span :class="{ 'text-danger': Number(data.item.irr) < 0 }">
        {{ ppFloat(data.item.irr) }}
      </span>
    </template>
    <template v-slot:cell(pnl_trade)="data">
      <span :class="{ 'text-danger': Number(data.item.pnl_trade) < 0 }">
        {{ format(data.item.pnl_trade) }}
      </span>
    </template>
  </b-table>
</template>

<script>
import { ppMixin, tableMixin } from '@/mixins'

export default {
  props: ['data'],
  mixins: [ppMixin, tableMixin],
  data: function() {
    return {
      fields: [
        {key: 'tennor', label: 'Contract', sortable: true},
        {key: 'commodity', label: 'Commodity', sortable: true},
        {key: 'trade_entry', label: 'Entry Date', sortable: true},
        {key: 'exit_date', label: 'Exit Date', sortable: true, class: 'text-right'},
        {key: 'action', label: 'Action', sortable: true},
        {key: 'type', label: 'Trade Type', sortable: true},
        {key: 'net_lots', label: 'Net Lots', sortable: true, class: 'text-right'},
        {key: 'premium_exposure', label: 'Premium Exposure', sortable: true, class: 'text-right'},
        {key: 'margin_exposure', label: 'Margin Exposure', sortable: true, class: 'text-right'},
        {key: 'strike', label: 'Strike', sortable: true, class: 'text-right'},
        {key: 'price', label: 'Price', sortable: true, class: 'text-right'},
        {key: 'pnl_trade', label: 'Net Outcome', sortable: true, class: 'text-right'},
        {key: 'irr', label: 'IRR', sortable: true, class: 'text-right'},
        {key: 'days_held', label: 'Days Held', sortable: true, class: 'text-right'}
      ]
    }
  },
  computed: {
    items() {
      return this.data.toCollection().map(item => {
        for (let c of ['premium_exposure', 'margin_exposure', 'strike', 'price']) {
          item[c] = this.hideZeroFormat(item[c])
        }
        return item
      })
    },
  }
}
</script>
