<template>
  <div>
    <b-table-simple small class="mb-0">
      <b-tbody>
        <b-tr>
          <b-th>FFAs</b-th>
          <b-td>
            <big><big>
                <strong v-bind:class="data.recommendationClass">
                  {{ recommendationDisplay(data) }}
                </strong>
              </big></big>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Options</b-th>
          <b-td>
            <big><big>
                <strong v-bind:class="data.recommendationClass">
                  {{ optionsRecommendationDisplay(data) }}
                </strong>
              </big></big>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple small v-if="data.commodity == 'panamax'">
      <b-tbody>
        <b-tr>
          <b-th></b-th>
          <b-th class="text-right">5TC</b-th>
          <b-th class="text-right">4TC</b-th>
        </b-tr>
        <b-tr>
          <b-th>Validity range</b-th>
          <b-td class="text-right">
            {{ pricingCriteria(data, 0) }}
          </b-td>
          <b-td class="text-right">
            {{ pricingCriteria(data, -1366) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Market price</b-th>
          <b-td class="text-right">
            {{ data.marketPrice }}
            <span v-bind:class="data.marketPriceDiffClass">
              ({{ data.marketPriceDiff }})
            </span>
          </b-td>
          <b-td class="text-right">
            {{ data.marketPrice - 1336 }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Spot price</b-th>
          <b-td class="text-right">
            {{ data.spotPrice }}
            <span v-bind:class="data.spotPriceDiffClass">
              ({{ data.spotPriceDiff }})
            </span>
          </b-td>
          <b-td class="text-right">
            {{ data.spotPrice - 1336 }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="3" class="text-right"><small><small>All prices $ / day</small></small></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple small v-else>
      <b-tbody>
        <b-tr>
          <b-th>Validity range</b-th>
          <b-td class="text-right">
            {{ pricingCriteria(data, 0) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Market price</b-th>
          <b-td class="text-right">
            {{ data.marketPrice }}
            <span v-bind:class="data.marketPriceDiffClass">
              ({{ data.marketPriceDiff }})
            </span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Spot price</b-th>
          <b-td class="text-right">
            {{ data.spotPrice }}
            <span v-bind:class="data.spotPriceDiffClass">
              ({{ data.spotPriceDiff }})
            </span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="2" class="text-right"><small><small>All prices $ / day</small></small></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  name: 'ContractSummary',
  props: ['data'],
  mixins: [ppMixin]
}
</script>
