<template>
  <div class="sidebar-sticky">
    <b-nav class="flex-column">
      <b-nav-item to="/" exact exact-active-class="active">
        <b-icon-house></b-icon-house> Dashboard
      </b-nav-item>
      <b-nav-item
        v-for="commodity in $store.state.commodities"
        :key="commodity.name"
        :to="commodity.name"
        exact exact-active-class="active">
        <b-icon-app></b-icon-app> {{ commodity.displayName }}
      </b-nav-item>
      <b-nav-item
        v-if="$store.getters.currentMode == 'commodities' && $store.getters.canViewPositions"
        to="/positions"
        exact
        exact-active-class="active"
      >
        <b-icon-list></b-icon-list>
        Positions
      </b-nav-item>
      <b-nav-item
        v-if="
        $store.getters.currentContext.display == 'C10 and C14'|| 
        $store.getters.currentContext.display == 'C5TC and P5TC'|| 
        $store.getters.currentContext.display == 'Capesize' ||
        $store.getters.currentContext.display == 'Panamax' ||
        $store.getters.currentContext.display == 'Supramax'" 
        to="/documentation"
        exact
        exact-active-class="active">
        <b-icon-question-circle></b-icon-question-circle> Documentation
      </b-nav-item>
      <b-nav-item
        v-if="['commodities', 'viterra', 'viterra-ais', 'sedna-ais'].indexOf($store.getters.currentMode) >= 0 && $store.getters.canViewAIS"
        to="/ais"
        exact
        exact-active-class="active">
        <b-icon-broadcast></b-icon-broadcast> AIS
      </b-nav-item>
      <b-nav-item
        v-if="['commodities', 'viterra', 'sedna-ais'].indexOf($store.getters.currentMode) >= 0 && $store.getters.canViewAIS"
        to="/aissuprahandy"
        exact
        exact-active-class="active">
        <b-icon-broadcast></b-icon-broadcast> AIS Positions
      </b-nav-item>
      <b-nav-item
        v-for="view in viterraForecastViews"
        :key="view.slug"
        :to="'/forecasts/' + view.slug"
        exact
        exact-active-class="active">
        <b-icon-bar-chart></b-icon-bar-chart> {{view.name}}
      </b-nav-item>
      <b-nav-item
        v-if="$store.getters.currentMode == 'viterra'"
        to="/forecasts-documentation"
        exact
        exact-active-class="active">
        <b-icon-question-circle></b-icon-question-circle> Documentation
      </b-nav-item>
    </b-nav>
    <div>
      <b-alert :show="updateExists" variant="info">
        <span class="mr-2">A new version is available!</span>
        <b-button
          variant="info"
          size="sm"
          @click="refreshApp">
          Update now
        </b-button>
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: [],
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    };
  },
  computed: {
    viterraForecastViews() {
      if (this.$store.getters.currentMode != 'viterra') return []
      return [
        {'name': 'P1A W4-6', 'slug': 'p1a'},
        {'name': 'P2A W4-6', 'slug': 'p2a'},
        {'name': 'P3A W4-6', 'slug': 'p3a'},
        {'name': 'P6 W4-6', 'slug': 'p6'},
        {'name': 'Panamax M1-3 W4', 'slug': 'panamax-monthly'},
        {'name': 'Panamax Q2-4 W6', 'slug': 'panamax-quarterly'},
        {'name': 'Capesize M1-3 W4', 'slug': 'capesize-monthly'},
        {'name': 'Capesize Q2-4 W6', 'slug': 'capesize-quarterly'},
      ]
    }
  },
  created () {
    document.addEventListener(
      'swUpdated', this.showRefreshUI, { once: true }
    );
    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      }
    );
  },
  methods: {
    showRefreshUI (e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp () {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
}
</script>
