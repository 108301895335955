<template>
  <b-form>
    <b-row v-if="pendingPositions.length == 0">
      <b-col>
        <b-alert class="text-center" variant="default" show>No Data Available</b-alert>
      </b-col>
    </b-row>
    <template v-for="pending in pendingPositions">
      <PendingTrade v-bind:pending="pending" v-bind:key="pending.id" />
    </template>
  </b-form>
</template>

<script>
import PendingTrade from '@/components/PendingTrade.vue'

export default {
  name: 'PendingPositionsTable',
  components: { PendingTrade },
  computed: {
    pendingPositions() {
      const trading_ctx = this.$store.getters.tradingContext;
      if (trading_ctx) {
        return trading_ctx.pending_trades
      }
      return []
    }
  }
}
</script>
