<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <template v-if="true">
        <ContractHeader v-bind:month="data.month" v-bind:date="data.date" />
        <b-card-text>
          <ContractSummary v-bind:data="data" />
        </b-card-text>
      </template>
      <template v-else>
        <b-card-title>{{ data.contract }}</b-card-title>
        <b-card-text>
          <div v-if="data.no_data"><h4>No Data Available</h4></div>
          <div v-else class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </b-card-text>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import ContractHeader from '@/components/ContractHeader.vue'
import ContractSummary from '@/components/ContractSummary.vue'

export default {
  name: 'ContractCard',
  props: ['data'],
  components: {ContractHeader, ContractSummary}
}
</script>
