<template>
  <div>
    <b-container fluid>
      <b-row>
        <h3>Equities Portfolio</h3>
      </b-row>
      <b-row>
        <b-col sm="12" class="pb-3">
          <b-card no-body>
            <b-card-body class="pb-0" body-bg-variant="light">
              <b-card-title>Overview</b-card-title>
                <b-table small v-bind:items="items" v-bind:fields="fields" />
              <b-card-text>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <Plotly
            ref="account_value"
            v-bind:data="data.accountValuePlotData"
            :layout="{title: 'Account Value'}"
            style="height: 300px;"
            :displaylogo="false"
            :responsive="true"
          />
        </b-col>
        <b-col sm="6">
          <Plotly
            ref="close_prices"
            v-bind:data="data.closePricesPlotData"
            :layout="{title: 'Close Prices'}"
            style="height: 300px;"
            :displaylogo="false"
            :responsive="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="mb2 pb-3" v-if="data.exitIndications">
          <b-card no-body>
            <b-card-body class="pb-0" body-bg-variant="light">
              <b-card-title>Exit Indications</b-card-title>
              <b-card-text>
                <b-table
                  hover show-empty small
                  :fields="data.exitIndications.fields"
                  :items="data.exitIndications.items"
                  empty-text="No exit indication"
                />
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6" class="mb2 pb-3" v-if="data.recommendations">
          <b-card no-body>
            <b-card-body class="pb-0" body-bg-variant="light">
              <b-card-title>Execution Recommendations</b-card-title>
              <b-card-text>
                <b-table
                  hover show-empty small
                  :fields="data.recommendations.fields"
                  :items="data.recommendations.items"
                  empty-text="No recommendation"
                />
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col class="pb-3" v-if="data.portfolioTableData">
          <b-card no-body>
            <b-card-body body-bg-variant="light">
              <b-card-title>Portfolio</b-card-title>
              <b-card-text>
                <b-table
                  hover small
                  :fields="data.portfolioTableData.fields"
                  :items="data.portfolioTableData.items"
                >
                  <template #custom-foot="data">
                    <b-tr>
                      <b-th>TOTAL</b-th>
                      <b-th class="text-right">{{data.fields[1].footer}}</b-th>
                      <b-th :class="data.fields[2].footerClass">{{format(data.fields[2].footer)}}</b-th>
                      <b-th :class="data.fields[3].footerClass">{{format(data.fields[3].footer)}}</b-th>
                      <b-th :class="data.fields[4].footerClass">{{(data.fields[4].footer * 100).toFixed(2)}}%</b-th>
                      <b-th :class="data.fields[5].footerClass">{{format(data.fields[5].footer)}}</b-th>
                      <b-th :class="data.fields[6].footerClass">{{data.fields[6].footer}}</b-th>
                      <b-th :class="data.fields[7].footerClass">{{format(data.fields[7].footer)}}</b-th>
                    </b-tr>
                  </template>
                </b-table>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="data.m2mTableData">
          <b-card no-body>
            <b-card-body class="pb-0" body-bg-variant="light">
              <b-card-title>Weekly Mark-to-Market</b-card-title>
              <b-card-text>
                <b-table
                  hover small
                  :fields="data.m2mTableData.fields"
                  :items="data.m2mTableData.items"
                >
                  <template #custom-foot="data">
                    <b-tr>
                      <b-th>TOTAL</b-th>
                      <b-th
                        v-for="field in data.fields.slice(1)"
                        :key="field.key"
                        :class="field.footerClass">
                        {{format(field.footer)}}
                    </b-th>
                    </b-tr>
                  </template>
                </b-table>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Plotly } from '@pankgeorg/vue-plotly'
import { currencyFormatter } from '@/mixins'

export default {
  props: ['data'],
  components: {
    Plotly
  },
  methods: {
    format: x => currencyFormatter.format(x)
  },
  computed: {
    items() {
      const items = [
        {
          'account_value': this.format(this.data.dayData['account_value']),
          'cash_balance': this.format(this.data.dayData['balance']),
          'pct_avail_cap_usage': (
            this.data.dayData['pct_avail_cap_used'] * 100.0
          ).toFixed(2) + '%',
          'cum_net_profit': this.format(
            this.data.dayData['cum_net_profit'] || 0
          ),
          'm2m': this.format(this.data.dayData['m2m']),
          'value_at_risk': this.format(this.data.dayData['var']),
          'var_usage': (this.data.dayData['var_usage'] * 100).toFixed(2) + '%',
          'sharpe_ratio': (
            this.data.dayData['sharpe_ratio'] ?
            this.data.dayData['sharpe_ratio'].toFixed(2) : 'N/A'
          ),
          'sortino_ratio': (
            this.data.dayData['sortino_ratio'] ?
            this.data.dayData['sortino_ratio'].toFixed(2) : 'N/A'
          ),
          'annual_return': (
            this.data.dayData['annual_return'] ?
            (this.data.dayData['annual_return'] * 100).toFixed(2) + '%': 'N/A'
          ),
          'max_drawdown': (
            this.data.dayData['max_drawdown'] ?
            (this.data.dayData['max_drawdown'] * 100).toFixed(2) + '%': 'N/A'
          )
        }
      ]
      if (!this.$store.getters.isTBI) {
        delete items[0]['cash_balance']
        delete items[0]['pct_avail_cap_usage']
      }
      return items
    },
    fields() {
      let fields = [
        {key: 'account_value', thClass: 'text-right', tdClass: 'text-right'},
        {key: 'cash_balance', thClass: 'text-right', tdClass: 'text-right'},
        {
          key: 'pct_avail_cap_usage', label: '% Avail. Cap. Used',
          thClass: 'text-right', tdClass: 'text-right'
        },
        {
          key: 'cum_net_profit', label: 'Cum. Net Profit',
          thClass: 'text-right', tdClass: 'text-right'
        },
        {
          key: 'm2m', label: 'M2M',
          thClass: 'text-right', tdClass: 'text-right'
        },
        {
          key: 'value_at_risk', label: 'Value-At-Risk',
          thClass: 'text-right', tdClass: 'text-right'
        },
        {
          key: 'var_usage', label: 'VaR Usage',
          thClass: 'text-right', tdClass: 'text-right'
        },
        {key: 'sharpe_ratio', thClass: 'text-right', tdClass: 'text-right'},
        {key: 'sortino_ratio', thClass: 'text-right', tdClass: 'text-right'},
        {key: 'annual_return', thClass: 'text-right', tdClass: 'text-right'},
        {key: 'max_drawdown', thClass: 'text-right', tdClass: 'text-right'},
      ]
      if (!this.$store.getters.isTBI) {
        fields = fields.filter(
          f => (f.key != 'cash_balance' && f.key != 'pct_avail_cap_usage')
        )
      }
      return fields
    }
  }
}
</script>
