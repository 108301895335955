<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <h3>
            <b-form-radio-group
              class="pl-4"
              buttons
              v-model="forecastMode"
              button-variant="outline-primary"
              :options="[{text: 'Present Indicator', value: 'forecast'}, {text: 'Previous 3 Days Indicators', value: 'progression'}]"
            >
            </b-form-radio-group>
          </h3>
        </b-col>
        <b-col
          v-for="(route, routeIndex) in orderedRoutes"
          v-bind:key="route.name"
          class="mt-4"
        >
          <b-row v-if="route.description">
            <b-col class="text-center">
              <h5>{{ route.description }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(week, weekIndex) in route.weeks"
              v-bind:key="week.number"
              :class="separator(route, routeIndex, weekIndex)"
            >
              <D3RouteForecast
                ref="`{route.name}_fcast_w{week.number}`"
                v-bind:data="week.d3ForecastData"
                :week="week.number"
                :route="route.name"
                v-bind:mode="forecastMode"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5 border-top">
        <b-col sm="6" class="border-right pt-2">
          <b-row>
            <b-col sm="12">
              <h5>
                Feature Importance
                <small>
                  <b-icon
                    id="fi_info"
                    icon="info-circle"
                  >
                  </b-icon>
                  <b-tooltip target="fi_info">
                    All categories contain both direct and derived features (inputs),
                    such as technical / statistical properties of
                    a price / time series in that category
                  </b-tooltip>
                </small>
              </h5>
            </b-col>
            <b-col
              v-for="entry in featuresLegends"
              v-bind:key="entry.label"
              class="text-center"
            >
              <span>
                <big>
                  <big>
                    <span :style="`color: ${entry.color}`">●</span>
                  </big>
                </big>&nbsp;{{ entry.label }}
              </span>
            </b-col>
          </b-row>
          <b-row
            v-for="route in orderedRoutes"
            v-bind:key="route.name"
          >
            <b-col
              v-for="week in route.weeks"
              v-bind:key="week.number"
              class="text-center"
            >
              <D3RouteFeatures
                ref="`{route.name}_features_w{week.number}`"
                v-bind:data="week.d3FeaturesData"
                :week="week.number"
                :route="route.name"
                :canvasSize="250"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6" class="pt-2">
          <b-row class="pl-2">
            <b-col sm="12">
              <h5>
                Model Performance
                <small>
                  <b-icon
                    id="mp_info"
                    icon="info-circle"
                  >
                  </b-icon>
                  <b-tooltip target="mp_info">
                    Recent performance expressed in percentage terms relative to
                    model’s total historical performance
                  </b-tooltip>
                </small>
                <b-form-radio-group
                  class="pl-4"
                  buttons
                  size="sm"
                  v-model="errorMode"
                  button-variant="outline-primary"
                  :options="[{text: 'Relative', value: 'relative'}, {text: 'Absolute', value: 'absolute'}]"
                />
                <b-form-radio-group
                  class="pl-4"
                  buttons
                  size="sm"
                  v-model="windowSize"
                  button-variant="outline-primary"
                  :options="[{text: '6M', value: 'past_six_months'}, {text: '1Y', value: 'past_year'}]"
                />
              </h5>
            </b-col>
            <b-col
              v-for="entry in performanceLegends"
              v-bind:key="entry.label"
              class="text-center"
            >
              <span>
                <big>
                  <big><span :style="`color: ${entry.color}`">●</span></big>
                </big>&nbsp;{{ entry.label }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="route in orderedRoutes"
              v-bind:key="route.name"
            >
              <b-row>
                <b-col
                  v-for="week in route.weeks"
                  v-bind:key="week.number"
                  class="text-center"
                >
                  <D3RoutePerformance
                    ref="`{route.name}_features_w{week.number}`"
                    v-bind:data="week.d3PerformanceData[errorMode][windowSize]"
                    :week="week.number"
                    :route="route.name"
                    :canvasSize="250"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import D3RouteForecast from '@/components/D3RouteForecast.vue'
import D3RouteFeatures from '@/components/D3RouteFeatures.vue'
import D3RoutePerformance from '@/components/D3RoutePerformance.vue'
import { colorsMixin } from '@/mixins'

export default {
  props: ['data'],
  data() {
    return {
      forecastMode: 'forecast',
      errorMode: 'relative',
      windowSize: 'past_six_months'
    }
  },
  components: {
    D3RouteForecast,
    D3RouteFeatures,
    D3RoutePerformance,
  },
  mixins: [ colorsMixin ],
  computed: {
    orderedRoutes() {
      if (!this.data.routes) return []
      if (this.data.routes[0].name == 'C14') {
        const descriptions = {
          'C10': 'China - W. Aus. - China',
          'C14': 'China - Brazil - China',
          'C5TC': 'BCI 5TC'
        }
        return this.data.routes.map(x => Object.assign(
          {description: descriptions[x.name]},
          x
        ))
      }
      return this.data.routes
    },
    featuresLegends() {
      return [
        {label: 'Target', color: this.colors.CAT1},
        {label: 'Baltic\xa0Indices', color: this.colors.CAT6},
        {label: 'Baltic\xa0Futures', color: this.colors.CAT2},
        {label: 'Other', color: this.colors.CAT3},
        {label: 'Commodity\xa0Futures', color: this.colors.CAT4},
        {label: 'Equities\xa0/\xa0ETF', color: this.colors.CAT5},
      ]
    },
    performanceLegends() {
      return [
        {label: 'Directional\xa0Accuracy', color: this.colors.CAT3},
        {label: 'Indicator\xa0Accuracy', color: this.colors.CAT8},
        {label: 'Range\xa0Accuracy', color: this.colors.CAT5},
      ]
    }
  },
  methods: {
    separator(route, routeIndex, weekIndex) {
      let condition = routeIndex < (this.orderedRoutes.length - 1)
      if (this.orderedRoutes.length == 2) {
        condition = routeIndex == 0 && weekIndex == route.weeks.length - 1
      }
      return condition ? 'border-right text-center' :  'text-center'
    }
  }
}
</script>
