<template>
  <div class="text-center">
    <b-spinner variant="primary" label="Loading..."></b-spinner>
  </div>
</template>
<script>
export default {
  name: 'Loading'
}
</script>
