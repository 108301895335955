<template>
  <div>
    <b-container fluid>
      <h2>EOD situation</h2>
      <b-row>
        <b-col sm="4">
          <h5>Portfolio</h5>
          <b-table-simple small bordered>
            <b-tbody>
              <DiffTr
                :data="data"
                label="Account Value"
                variable="account_value"
                type="financials"
                :higherIsBetter="true"
                />
              <DiffTr
                :data="data"
                label="Drawdown"
                variable="drawdown"
                type="percentage"
                :higherIsBetter="true"
              />
              <DiffTr
                :data="data"
                label="Mark-to-market"
                variable="m2m"
                type="financials"
                :higherIsBetter="true"
              />
              <DiffTr
                :data="data"
                label="Partially Realized"
                variable="partially_realized"
                type="financials"
                :higherIsBetter="true"
              />
              <DiffTr
                :data="data"
                label="Value-at-risk"
                variable="value_at_risk"
                type="financials"
                :higherIsBetter="false"
              />
              <DiffTr
                :data="data"
                label="Expected Shortfall"
                variable="expected_shortfall"
                type="financials"
                :higherIsBetter="false"
              />
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col sm="4">
          <h5>Market prices</h5>
          <b-table-simple small bordered>
            <b-tbody>
              <b-tr class="text-center">
                <b-th></b-th>
                <b-th colspan="2">Capesize</b-th>
                <b-th colspan="2">Panamax</b-th>
              </b-tr>
              <CommoditiesDiffTr
                :data="data"
                label="Index"
                variable="index"
                type="financials"
                :higherIsBetter="true"
              />
              <CommoditiesDiffTr
                :data="data"
                label="M0"
                variable="M0_ffa_price"
                type="financials"
                :higherIsBetter="true"
              />
              <CommoditiesDiffTr
                :data="data"
                label="M1"
                variable="M1_ffa_price"
                type="financials"
                :higherIsBetter="true"
              />
              <CommoditiesDiffTr
                :data="data"
                label="M2"
                variable="M2_ffa_price"
                type="financials"
                :higherIsBetter="true"
              />
              <CommoditiesDiffTr
                :data="data"
                label="M3"
                variable="M3_ffa_price"
                type="financials"
                :higherIsBetter="true"
              />
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col sm="4">
          <h5>Financials</h5>
          <b-table-simple small bordered>
            <b-tbody>
              <DiffTr
                :data="data"
                label="Annualized Returns"
                variable="simple_annualized_returns"
                type="percentage"
                :higherIsBetter="true"
              />
              <DiffTr
                :data="data"
                label="Annualized Volatility"
                variable="annualized_volatility"
                type="percentage"
                :higherIsBetter="false"
              />
              <DiffTr
                :data="data"
                label="Sharpe Ratio"
                variable="sharpe_ratio"
                type="float"
                :higherIsBetter="true"
              />
              <DiffTr
                :data="data"
                label="Sortino Ratio"
                variable="sortino_ratio"
                type="float"
                :higherIsBetter="true"
              />
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <h2>Trading</h2>
      <b-row>
        <b-col sm="12">
          <b-table-simple small bordered>
            <b-tr class="text-right">
              <b-th colspan="2"></b-th>
              <b-th colspan="2" class="text-center"><h4>Capesize</h4></b-th>
              <b-th colspan="2" class="text-center"><h4>Panamax</h4></b-th>
              <b-th colspan="2" class="text-center"><h4>Total</h4></b-th>
            </b-tr>
            <CommodityContractDiffTr
                :data="data"
                label="Net Delta"
                variable="net_delta"
                contract="M0"
                type="float"
                :higherIsBetter="false"
                :numRows="3"
            />
            <CommodityContractDiffTr
              :data="data"
              label="Mark-to-market"
              variable="m2m"
              contract="M0"
              type="financials"
              :higherIsBetter="true"
            />
            <CommodityContractDiffTr
              :data="data"
              label="Partially Realized"
              variable="partially_realized"
              contract="M0"
              type="financials"
              :higherIsBetter="true"
            />
            <CommodityContractDiffTr
                :data="data"
                label="Net Delta"
                variable="net_delta"
                contract="M1"
                type="float"
                :higherIsBetter="false"
                :numRows="3"
            />
            <CommodityContractDiffTr
              :data="data"
              label="Mark-to-market"
              variable="m2m"
              contract="M1"
              type="financials"
              :higherIsBetter="true"
            />
            <RecommendationTr
                :data="data"
                contract="M1"
            />
            <CommodityContractDiffTr
                :data="data"
                label="Net Delta"
                variable="net_delta"
                contract="M2"
                type="float"
                :higherIsBetter="false"
                :numRows="3"
            />
            <CommodityContractDiffTr
              :data="data"
              label="Mark-to-market"
              variable="m2m"
              contract="M2"
              type="financials"
              :higherIsBetter="true"
            />
            <RecommendationTr
                :data="data"
                contract="M2"
            />
            <CommodityContractDiffTr
                :data="data"
                label="Net Delta"
                variable="net_delta"
                contract="M3"
                type="float"
                :higherIsBetter="false"
                :numRows="3"
            />
            <CommodityContractDiffTr
              :data="data"
              label="Mark-to-market"
              variable="m2m"
              contract="M3"
              type="financials"
              :higherIsBetter="true"
            />
            <RecommendationTr
              :data="data"
              contract="M3"
            />
            <TotalTr
              :data="data"
              label="Net Delta"
              variable="net_delta"
              type="float"
              :higherIsBetter="false"
              :numRows="2"
            />
            <TotalTr
              :data="data"
              label="Mark-to-market"
              variable="m2m"
              type="financials"
              :higherIsBetter="true"
            />
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { ppMixin } from '@/mixins'
import DiffTr from '@/components/DiffTr.vue'
import CommoditiesDiffTr from '@/components/CommoditiesDiffTr.vue'
import CommodityContractDiffTr from '@/components/CommodityContractDiffTr.vue'
import RecommendationTr from '@/components/RecommendationTr.vue'
import TotalTr from '@/components/TotalTr.vue'

export default {
  props: ['data'],
  mixins: [ppMixin],
  components: {
    DiffTr,
    CommoditiesDiffTr,
    CommodityContractDiffTr,
    RecommendationTr,
    TotalTr
  }
}
</script>
