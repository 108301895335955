<template>
  <b-tr>
    <b-th>{{ label }}</b-th>
    <b-td class="text-right">{{ mainValue }}</b-td>
    <b-td :class="diffClass">{{ diffValue }}</b-td>
  </b-tr>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  props: ['data', 'label', 'variable', 'type', 'higherIsBetter'],
  mixins: [ppMixin],
  computed: {
    mainValue() {
      return this.formatByType(this.data.dayData[this.variable])
    },
    arrow() {
      if (this.data.dayData[this.variable] == this.data.previousDayData[this.variable]) {
        return ' \uff1d'
      }
      if (this.data.dayData[this.variable] > this.data.previousDayData[this.variable]) {
        return ' \u25b2'
      }
      return ' \u25bc'
    },

    diffValue() {
      return this.formatByType(
        this.data.dayData[this.variable] - this.data.previousDayData[this.variable]
      ) + this.arrow
    },
    diffClass() {
      const cls = {'text-right': true}
      if (this.data.dayData[this.variable] > this.data.previousDayData[this.variable]) {
        cls[this.higherIsBetter ? 'text-success' : 'text-danger'] = true
      }
      if (this.data.dayData[this.variable] < this.data.previousDayData[this.variable]) {
        cls[this.higherIsBetter ? 'text-danger' : 'text-success'] = true
      }
      return cls
    }
  },
  methods: {
    formatByType(value) {
      console.log(this.type)
      if (this.type == 'financials') {
        return this.format(value)
      } else if (this.type == 'percentage') {
        return this.ppFloat(value * 100.0) + '%'
      } else if (this.type == 'float') {
        return this.ppFloat(value)
      }
      return value
    }
  }
}
</script>
