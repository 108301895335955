<template>
  <b-card no-body>
    <b-card-body class="pb-0" body-bg-variant="light">
      <b-card-title>Realized</b-card-title>
      <b-card-text>
        <b-table-simple small>
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th class="text-right">Cum. net profit</b-th>
              <b-th class="text-right">Profit/loss ratio</b-th>
              <b-th class="text-right">Avg. net profit/trade</b-th>
              <b-th class="text-right">Avg. trade duration</b-th>
              <b-th class="text-right">Num. of Trades</b-th>
              <b-th class="text-right">Avg. VaR</b-th>
              <b-th class="text-right">Max VaR</b-th>
              <b-th class="text-right">Sharpe ratio</b-th>
              <b-th class="text-right">Sortino ratio</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <RealizedRow v-bind:data="data.ytd" title="YTD" />
            <RealizedRow v-bind:data="data.tmr" title="12 Months Rolling" />
            <RealizedRow v-bind:data="data.overall" :title="data.overall.title" />
          </b-tbody>
        </b-table-simple>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import RealizedRow from '@/components/RealizedRow.vue'

export default {
  name: 'RealizedCard',
  props: {
    data: Object,
  },
  components: {RealizedRow},
}
</script>
