<template>
  <b-tr>
    <b-th>{{ label }}</b-th>
    <b-td class="text-right">{{ mainValue('capesize') }}</b-td>
    <b-td :class="diffClass('capesize')">{{ diffValue('capesize') }}</b-td>
    <b-td class="text-right">{{ mainValue('panamax') }}</b-td>
    <b-td :class="diffClass('panamax')">{{ diffValue('panamax') }}</b-td>
  </b-tr>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  props: ['data', 'label', 'variable', 'type', 'higherIsBetter'],
  mixins: [ppMixin],
  methods: {
    value(commodity) {
      return this.data.dayData[commodity + '_' + this.variable]
    },
    prevValue(commodity) {
      return this.data.previousDayData[commodity + '_' + this.variable]
    },
    mainValue(commodity) {
      return this.formatByType(this.value(commodity))
    },
    arrow(commodity) {
      if (this.value(commodity) == this.prevValue(commodity)) {
        return ' \uff1d'
      }
      if (this.value(commodity) > this.prevValue(commodity)) {
        return ' \u25b2'
      }
      return ' \u25bc'
    },
    diffValue(commodity) {
      return this.formatByType(
        this.value(commodity) - this.prevValue(commodity)
      ) + this.arrow(commodity)
    },
    diffClass(commodity) {
      const cls = {'text-right': true}
      if (this.value(commodity) > this.prevValue(commodity)) {
        cls[this.higherIsBetter ? 'text-success' : 'text-danger'] = true
      }
      if (this.value(commodity) < this.prevValue(commodity)) {
        cls[this.higherIsBetter ? 'text-danger' : 'text-success'] = true
      }
      return cls
    },
    formatByType(value) {
      if (this.type == 'financials') {
        return this.format(value)
      } else if (this.type == 'percentage') {
        return this.ppFloat(value * 100.0) + '%'
      } else if (this.type == 'float') {
        return this.ppFloat(value)
      }
      return value
    }
  }
}
</script>
