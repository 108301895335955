<template>
  <b-navbar-nav v-if="$auth.isAuthenticated" class="ml-md-auto align-items-center">
    <b-form inline v-if="visible" class="mt-0 mr-2">
      <b-button
        size="sm"
        class="mr-2 border-0"
        v-on:click="subtractDay"
        variant="outline-secondary"
      >&laquo;</b-button>
      <b-form-datepicker
        size="sm"
        style="min-width: 295px;"
        class="mt-0 mb-0 pt-0 pb-0"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        reset-button
      ></b-form-datepicker>
      <b-button
        size="sm"
        class="ml-2 border-0"
        v-on:click="addDay"
        variant="outline-secondary"
      >&raquo;</b-button>
    </b-form>
    <b-nav-item></b-nav-item>
    <b-nav-item>
      <span v-on:click="visible = !visible">
        <b-icon-gear></b-icon-gear>
      </span>
    </b-nav-item>
    <b-nav-item v-if="$store.getters.effectiveDate">
      <span
        id="effective-date"
        v-bind:class="{'text-success': $store.getters.isLastDate, 'text-warning': !$store.getters.isLastDate}">
        {{ $store.getters.effectiveDate }}
      </span>
      <b-tooltip target="effective-date" triggers="hover">
        <span v-if="$store.getters.isLastDate">
          Displaying the latest data
        </span>
        <span v-else>
          Warning - Historical data is on display
        </span>
      </b-tooltip>
    </b-nav-item>
    <template v-if="connecting">
    <b-nav-item id="connecting">
      <b-spinner small variant="warning" type="grow"></b-spinner>
    </b-nav-item>
    <b-tooltip target="connecting" triggers="hover">
      Trying to connect to the backend...
    </b-tooltip>
    </template>
    <template v-else>
    <b-nav-item id="connected">
      <b-icon-lightning-fill variant="primary"></b-icon-lightning-fill>
    </b-nav-item>
    <b-tooltip target="connected" triggers="hover">
      Connected to the backend
    </b-tooltip>
    </template>
    <b-nav-item v-if="$store.getters.canSwitchTenant">
      <b-dropdown
        right
        size="sm"
        variant="outline-default"
        :text="$store.getters.currentTenantDisplayName"
      >
        <b-dropdown-item
          v-for="(tenant, name) in $store.getters.authorizedTenants"
          :active="tenant.display == $store.getters.currentTenantDisplayName"
          v-on:click="$store.dispatch('switchTenant', name)"
          :key="name">
          {{ tenant.display }}
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item v-if="$store.getters.canSwitchContext">
      <b-dropdown
        v-if="$store.getters.currentContext"
        right
        size="sm"
        variant="outline-default"
        :text="$store.getters.currentContext.display"
      >
        <b-dropdown-group
          v-if="$store.getters.hasCommoditiesContexts"
          id="commodities-group"
          header="Commodities Portfolios"
        >
          <b-dropdown-item
            v-for="(context, name) in $store.getters.commoditiesContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ context.display }}
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="$store.getters.hasEquitiesContexts"
          id="equities-group"
          header="Equities Portfolios"
        >
          <b-dropdown-item
            v-for="(context, name) in $store.getters.equitiesContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ context.display }}
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="$store.getters.hasRoutesContexts"
          id="routes-group"
          header="Routes Market Indicators"
        >
          <b-dropdown-item
            v-for="(routeCtx, name) in $store.getters.routesContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ routeCtx.display }}
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="$store.getters.hasViterraContexts"
          id="viterra-group"
          header="Forecasts"
        >
          <b-dropdown-item
            v-for="(viterraCtx, name) in $store.getters.viterraContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ viterraCtx.display }}
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="$store.getters.hasViterraAISContexts"
          id="viterra-ais-group"
          header="Viterra AIS"
        >
          <b-dropdown-item
            v-for="(viterraAISCtx, name) in $store.getters.viterraAISContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ viterraAISCtx.display }}
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="$store.getters.hasSednaAISContexts"
          id="sedna-ais-group"
          header="Sedna AIS"
        >
          <b-dropdown-item
            v-for="(sednaAISCtx, name) in $store.getters.sednaAISContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ sednaAISCtx.display }}
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="$store.getters.hasBAMContexts"
          id="bam-group"
          header="BAM"
        >
          <b-dropdown-item
            v-for="(bamCtx, name) in $store.getters.bamContexts"
            :active="name == $store.state.selected_context"
            v-on:click="$store.dispatch('switchContext', name)"
            :key="name">
            {{ bamCtx.display }}
          </b-dropdown-item>
        </b-dropdown-group>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item id=avatar>
      <b-avatar
        :src="$store.getters.avatar"
      />
    </b-nav-item>
    <b-tooltip target="avatar" triggers="hover">
      {{ $store.getters.userName }}
    </b-tooltip>
    <b-nav-item>
      <span v-on:click="logout()">
        <b-icon-power id="logout"></b-icon-power>
        <b-tooltip target="logout" triggers="hover">
          Logout
        </b-tooltip>
      </span>
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Toolbar',
  props: ['value', 'connecting'],
  sockets: {
    connect() {
      this.$store.dispatch('connected');
    },
    disconnect() {
      this.$store.dispatch('disconnected');
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    subtractDay() {
      this.$emit('input', moment(this.value || undefined).subtract(1, 'day').format('YYYY-MM-DD'))
    },
    addDay() {
      this.$emit('input', moment(this.value || undefined).add(1, 'day').format('YYYY-MM-DD'))
    }
  },
  data: function() {
    return {
      visible: false
    }
  }
}
</script>
