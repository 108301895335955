<template>
  <b-tr>
    <b-th>Recommendation</b-th>
    <b-td
      :class="recClass('capesize')"
      :colspan="!this.action('capesize') ? 2 : 1"
    >
      <b>{{ recommendation('capesize') }}</b>
    </b-td>
    <b-td
      :class="sigClass('capesize')"
      v-if="this.action('capesize')"
    >
      <small><b>{{ signal('capesize') }}</b></small>
    </b-td>
    <b-td
      :class="recClass('panamax')"
      :colspan="!this.action('panamax') ? 2 : 1"
    >
      <b>{{ recommendation('panamax') }}</b>
    </b-td>
    <b-td
      :class="sigClass('panamax')"
      v-if="this.action('panamax')"
    >
      <small><b>{{ signal('panamax') }}</b></small>
    </b-td>
    <b-td colspan="2"></b-td>
  </b-tr>
</template>

<script>
import { ppMixin } from '@/mixins'

export default {
  props: ['data', 'contract'],
  mixins: [ppMixin],
  methods: {
    action(commodity) {
      return this.data.dayData[commodity + '_' + this.contract + '_entry_signal_action']
    },
    lots(commodity) {
      return this.data.dayData[commodity + '_' + this.contract + '_entry_signal_lots']
    },
    signal(commodity) {
      const s = this.data.dayData[commodity + '_' + this.contract + '_entry_signal']
      const labels = {
        'strong_buy': 'Strong Buy',
        'buy': 'Buy',
        'soft_buy': 'Soft Buy',
        'strong_sell': 'Strong Sell',
        'sell': 'Sell',
        'soft_sell': 'Soft Sell',
        'agreement': 'Agreement',
        'ensemble': 'Ensemble',
        'stop_loss': 'Stop Loss',
        'profit_taking': 'Profit Taking',
        'delta_reduction': 'Delta Reduction'
      }
      return labels[s] || ''
    },
    recommendation(commodity) {
      const labels = {
        'buy': 'Buy',
        'sell': 'Sell'
      }
      const rec = labels[this.action(commodity)]
      if (!rec) {
        return 'Do Nothing'
      }
      return rec + ' ' + String(Math.round(this.lots(commodity))) + ' lots'
    },
    recClass(commodity) {
      const cls = {'text-uppercase': true}
      if (this.action(commodity) == 'buy') {
        cls['text-success'] = true
        cls['text-right'] = true
      } else if (this.action(commodity) == 'sell') {
        cls['text-danger'] = true
        cls['text-right'] = true
      } else {
        cls['text-center'] = true
      }
      return cls
    },
    sigClass(commodity) {
      const cls = {'text-uppercase': true, 'text-right': true}
      const s = this.signal(commodity)
      if (s.includes('Buy')) {
        cls['text-success'] = true
      } else if (s.includes('Sell')) {
        cls['text-danger'] = true
      } else {
        cls['text-warning'] = true
      }
      return cls
    },
  }
}
</script>
