<template>
  <b-row>
    <b-col cols="2">
      <b-form-group label="Trade">
        <h6>
          {{ ppCommodity(pending.commodity) }}
          M{{ pending.contract }}
          {{ ppTradeType(pending.type) }}
          {{ ppAction(pending.action) }}
          {{ pending.lots }}
          <small>{{ pending.trade_entry }}</small>
        </h6>
      </b-form-group>
    </b-col>
    <template v-if="pending.commodity == 'panamax'">
      <b-col cols="2">
        <b-form-group label="4TC Price" label-for="pmx-4tc-price-input">
          <b-form-input
            id="pmx-4tc-price-input"
            size="sm"
            type="number"
            v-model="price4TC"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Price">
          {{ format(price) }}
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Paid">
          {{ format(pending.trigger_paid) }}
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Maint. Margin">
          {{ format(pending.trigger_maint_margin) }}
        </b-form-group>
      </b-col>
    </template>
    <template v-if="pending.commodity == 'capesize'">
      <b-col cols="2">
        <b-form-group label="Premium" label-for="premium-input">
          <b-form-input
            id="premium-input"
            size="sm"
            type="number"
            v-model="premium"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Strike" label-for="strike-input">
          <b-form-input
            id="premium-input"
            size="sm"
            type="number"
            v-model="strike"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Paid">
          {{ format(paid) }}
        </b-form-group>
      </b-col>
    </template>
    <b-col cols="2">
      <b-form-group label="Actions">
        <b-button
          class="mr-2"
          size="sm"
          variant="primary"
          v-on:click="confirm()"
          :disabled="!canConfirm">
          Confirm
        </b-button>
        <b-button size="sm" variant="danger" v-on:click="cancel()">Cancel</b-button>
      </b-form-group>
    </b-col>
  </b-row>
</template>


<script>
import { ppMixin } from '@/mixins'

export default {
  name: 'PendingTrade',
  props: ['pending'],
  mixins: [ppMixin],
  data() {
    return {
      strike: null,
      price4TC: null,
      price: null,
      premium: null,
      paid: null,
      maint_margin: null
    }
  },
  computed: {
    canConfirm() {
      if (this.pending.commodity == 'panamax') {
        return this.price4TC != null && this.price != null
      } else if (this.pending.commodity == 'capesize') {
        return this.premium != null && this.strike != null && this.paid != null
      }
      return false
    }
  },
  methods: {
    confirm() {
      const trade = Object.assign({}, this.pending)
      if (trade.commodity == 'panamax') {
        trade.maint_margin = trade.trigger_maint_margin
        trade.price = this.price
        trade.paid = trade.trigger_paid
        trade.strike = null
      }
      if (trade.commodity == 'capesize') {
        trade.maint_margin = null
        trade.price = this.premium
        trade.paid = this.paid
        trade.strike = this.strike
      }
      this.$store.dispatch('confirm_position', trade)
    },
    cancel() {
      const trade = Object.assign({}, this.pending)
      this.$store.dispatch('cancel_position', trade)
    }
  },
  watch: {
    price4TC(value) {
      if (value == "") {
        this.price = null
        return
      }
      this.price = Number(value) + 1336
    },
    premium(value) {
      this.paid = Number(value) * this.pending.lots * (this.pending.action == "buy" ? 1 : -1)
    }
  }
}
</script>
