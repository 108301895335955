<template>
  <div>
  </div>
</template>

<script>
import * as d3 from "d3"
import { colorsMixin } from '@/mixins'

export default {
  props: {
    data: {
      default: () => ({
      })
    },
    route: {
      default: 'Route',
      type: String
    },
    week: {
      default: 1,
      type: Number
    },
    canvasSize: {
      default: 300,
      type: Number
    }
  },
  mixins: [ colorsMixin ],
  watch: {
    data(newData, oldData) {
      this.oldData = oldData
      this.$nextTick(this.draw)
    }
  },
  methods: {
    dataToEntries(data) {
      const total = Object.values(data)
        .filter(d => d > 0.01)
        .reduce((a, d) => a + d, 0)
      const convert = d3.scaleLinear().domain([0.0, total]).range([0.0, 1.0])
      const slices = Object.entries(data)
        .filter(([/* k */, v]) => v > 0.01)
        .map(([k, v]) => [k, convert(v)])
        .sort(([/* k1 */, v1], [/* k2 */, v2]) => v1 < v2)
      let pos = 0
      const entries = []
      slices.forEach(([k, v]) => {
        if (v == 1) {
          v = 0.99999
        }
        entries.push({
          category: k,
          start: pos,
          end: pos + v,
          value: v
        })
        pos += v
      })
      return entries
    },
    async draw() {
      const entries = this.dataToEntries(this.data)
      const oldEntries = this.dataToEntries(this.oldData)
      const size = this.canvasSize * 0.8
      const strokeWidth = size / 9.0
      const center = this.canvasSize / 2.0
      const width = size - strokeWidth
      const margin = size * 0.15
      const radius = (width - margin) / 2.0
      const colors = {
        'target': this.colors.CAT1,
        'baltic_futures': this.colors.CAT2,
        'ais': this.colors.CAT3,
        'commodity_futures': this.colors.CAT4,
        'equities_etf': this.colors.CAT5,
        'baltic_indices': this.colors.CAT6,
        'metals_and_materials': this.colors.CAT4,
        'energy': this.colors.CAT5,
        'agriculture': this.colors.CAT7,
        'global_macro': this.colors.CAT8,
      }

      const arcScale = d3.scaleLinear().domain([0.0, 1.0]).range([0.0, 2 * Math.PI])
      const valueToArcCoord = (v, r) => {
        const x = center + r * Math.sin(arcScale(v))
        const y = center - r * Math.cos(arcScale(v))
        return {x, y}
      }

      let svg = d3.select(this.$el).select("svg")

      if (svg.empty()) {
        svg = d3.select(this.$el)
          .append("svg")
          .attr("width", this.canvasSize)
          .attr("height", this.canvasSize)
      }

      svg.selectAll("text.title")
        .data([`${this.route} W+${this.week}`])
        .join("text")
          .attr("class", "title")
          .attr("text-anchor", "middle")
          .attr("font-family", "arial")
          .attr("font-weight", 500)
          .attr("fill", this.colors.BLACK)
          .attr("font-size", size / 13.0)
          .attr("x", center)
          .attr("y", center)
          .text(d => d)


      svg.selectAll("path.slice")
        .data(entries, x => x.category)
        .join("path")
          .attr("class", "slice")
          .attr("stroke-width", strokeWidth)
          .attr("fill", "none")
          .attr("stroke", d => colors[d.category])
          .transition()
          .attr("d", d => {
            const start = valueToArcCoord(d.start, radius)
            const end = valueToArcCoord(d.end, radius)
            const big = d.value > 0.5 ? 1 : 0
            const move = `M${start.x} ${start.y}`
            const arc = `A${radius} ${radius} 0 ${big} 1 ${end.x} ${end.y}`
            return `${move} ${arc}`
          })
          .attrTween("d", d => {
            const oldD = oldEntries.find(
              e => e.category === d.category
            ) || {start: 0, end: 0, value: 0}
            const startInterp = d3.interpolate(oldD.start, d.start)
            const endInterp = d3.interpolate(oldD.end, d.end)
            const valueInterp = d3.interpolate(oldD.value, d.value)
            return function(t) {
              const start = valueToArcCoord(startInterp(t), radius)
              const end = valueToArcCoord(endInterp(t), radius)
              const big = valueInterp(t) > 0.5 ? 1 : 0
              const move = `M${start.x} ${start.y}`
              const arc = `A${radius} ${radius} 0 ${big} 1 ${end.x} ${end.y}`
              return `${move} ${arc}`
            }
          })

      svg.selectAll("text.label")
        .data(entries, x => x.category)
        .join("text")
          .attr("class", "label")
          .attr("font-family", "arial")
          .attr("font-size", size / 18.0)
          .attr("fill", this.colors.BLACK)
          .attr("text-anchor", d => {
            const value = (d.start + d.end) / 2.0
            let anchor = "middle"
            if (value > 0.55 && value < 0.95) anchor = "end"
            if (value > 0.05 && value < 0.45) anchor = "start"
            return anchor
          })
          .attr("x", d => valueToArcCoord((d.start + d.end)/2.0, radius * 1.25).x)
          .attr("y", d => {
            const value = (d.start + d.end) / 2.0
            let y = valueToArcCoord(value, radius * 1.25).y
            if (value > 0.45 && value < 0.55) y += size / 24.0
            return y
          })
          .text(d => `${(d.value*100.0).toFixed(1)}%`)
    }
  },
  mounted() {
    this.oldData = this.data
    this.$nextTick(this.draw)
  },
  updated() {
    this.$nextTick(this.draw)
  }
}
</script>
